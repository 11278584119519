const version = '/v1'

export const API = {
  "USER": version + '/getUser',
  "LOGIN": version + '/login',
  // "REGISTER": version + '/register',
  "LOGOUT": version + '/logout',
  "UPDATE_PASSWORD": version + '/updatePassword',
  "UPLOAD_EXCEL_FILE": version + '/uploadExcelFile',
  "MULTIPLIER_RESOURCE": version + '/multipliers',
  "GET_STUDENTS_WITH_FILE": version + '/students',
  "GET_STUDENTS_ALL": version + '/students/allStudents',
  "RATING": version + '/rating',
  "FORCED_UNIT_RATING": version + '/rating/getForcedUnitRating',
  "FORCED_DEPARTMENT_RATING": version + '/rating/getForcedDepartmentRating',
  "EXPORT_STUDENTS_WITH_EXCEL": version + '/students/excelExport',
  "EXPORT_STUDENTS_ALL": version + '/students/allExport',
  "IMPORT_PUBLICATIONS": version + '/importPublications',
  "IMPORT_SCIENTIFIC_DEPARTMENT_PRACTICE": version + '/importScientificDepartmentPractice',
  "IMPORT_STUDENTS_WITH_ASU": version + '/importStudentsWithASU',
  "UPDATE_UNIT_SUPERVISORS": version + '/rating/setUnit',
  // students
  "GET_STUDENT": version + '/students',
  "SAVE_STUDENT_MARKS": version + '/students/saveMarks',
  "SUPERVISORS_LIST": version + '/supervisors/list',
  "IMPORT_TYPE_LIST": version + '/importType',
  "GET_UNITS_LIST": version + '/units',
  "GET_DEPARTMENTS_LIST": version + '/departments',
  // "STUDENT": version + '/student',
  "COUNT_NOT_IDENTIFIED_STUDENTS": version + '/students/countNotIdentifiedStudents',
  "COUNT_NOT_IDENTIFIED_SUPERVISORS": version + '/supervisors/countNotIdentifiedSupervisors',
  "SUPERVISORS": version + '/supervisors',
  "ROLES": version + '/roles',
  "USERS": version + '/users',
  "SETTINGS": version + '/settings',

  "PROGRESS_STUDENTS": version + '/progress/students',
  "PROGRESS_STUDENTS_FILTERS": version + '/progress/students/filters',
  "PROGRESS_STUDENTS_CREATE": version + '/progress/students/create',
  "ASU_ALL_STUDENTS": version + '/asu/students',
  "ASU_ALL_STUDENTS_SEARCH": version + '/asu/students/search',

  "PROGRESS_SUPERVISORS":  version + '/progress/supervisors',
  "PROGRESS_SUPERVISORS_FILTERS": version + '/progress/supervisors/filters',
  "PROGRESS_SUPERVISORS_CREATE":  version + '/progress/supervisors/create',
  "ASU_ALL_SUPERVISORS": version + '/asu/supervisors',
  "ASU_ALL_SUPERVISORS_SEARCH": version + '/asu/supervisors/search',

  "PROGRESS_MARKS":  version + '/progress/marks',
  "PROGRESS_MARKS_FILTERS": version + '/progress/marks/filters',
  "PROGRESS_MARKS_CREATE":  version + '/progress/marks/create',
  "PROGRESS_MARKS_APPROVE_VERIFICATION":  version + '/progress/marks/approve-verification',
  "PROGRESS_MARKS_CANCEL_VERIFICATION":  version + '/progress/marks/cancel-verification',

  "MARKS":  version + '/marks',
  "INDICATORS_FILTERS":  version + '/indicators/filters',
  "CONSTANT_TYPES":  version + '/indicators/constants',
  "INDICATORS_CREATE":  version + '/indicators/create',
  "INDICATORS":  version + '/indicators',

  "ACTIVITY_LEVELS":  version + '/activity-levels',
}


export const ALLOWED_REQUEST_PARAMETERS = {
  GET_PROGRESS_STUDENT: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    group: 'group',
    fullName: 'fullName',
    department: 'department',
    unit: 'unit'
  },

  GET_ASU_SEARCH_PERSON: {
    full_name: 'fullName',
  },

  GET_ASU_ALL_STUDENTS: {
    fullNameStudent: 'fullName',
    supervisors: 'supervisors',
    importType: 'importType',
    unitSupervisors: 'unitSupervisors',
    departmentSupervisors: 'departmentSupervisors',
    statusStudent: 'statusStudent',
    statusSupervisor: 'statusSupervisor',
  },

  GET_PROGRESS_MARKS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    mark: 'mark',
    verification: 'verification',
    student: 'student',
    supervisor: 'supervisor',
    department: 'department',
    unit: 'unit'
  },

  GET_INDICATORS: {
    unit: 'unit'
  },
}

