<template>
    <div>
      <slot></slot>
      <b-table hover :items="rating" :fields="fields" head-variant="primary" show-empty stacked="md">
        <template #cell(index)="row">
          {{ row.index+1 }}
        </template>

        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>

        <template #row-details="row">
          <b-row>
            <b-col cols="12" :lg="['F5','F9','F10'].includes(key)? 12 : 6" v-for="(formula, key) in row.item.formulas" :key="key">
              <b-card class="mb-4 px-0" v-if="enableFormulas.includes(key)">
                <component :is="key" :options="formula"></component>
              </b-card>
            </b-col>
          </b-row>
        </template>

        <template #cell(action)="row">
          <b-button
            class="mr-1" size="sm"
            variant="outline-primary"
            @click="row.toggleDetails"
            title="Формули"
            v-if="row.item.formulas"
          >
            <b-icon icon="calculator" font-scale="1"/>
          </b-button>

          <b-button class="mr-1" size="sm" variant="outline-info" v-if="visible" :to="{ name: 'RatingDepartmentPage', params: {
            id : row.item.id,
            name : row.item.name
          }}">
            <b-icon class="text-primary" icon="eye" font-scale="1"/>
          </b-button>
          <b-button
            v-if="row.item.errors.length"
            class="ml-1"
            v-b-tooltip
            title="Проблема з розрахунком"
            variant="warning"
            size="sm"
            @click="messageIsHasAsuIdToast(row.item.errors)"
          >
            <b-icon class="text-white" icon="exclamation-circle-fill" font-scale="1"/>
          </b-button>
        </template>
      </b-table>
    </div>
</template>

<script>
import F1 from '@/components/formulas/F1';
import F2 from '@/components/formulas/F2';
import F3 from "@/components/formulas/F3";
import F4 from "@/components/formulas/F4";
import F5 from "@/components/formulas/F5";
import F6 from "@/components/formulas/F6";
import F7 from "@/components/formulas/F7";
import F8 from "@/components/formulas/F8";
import F9 from "@/components/formulas/F9";
import F10 from "@/components/formulas/F10";

export default {
  name: "TableRating",
  components: {F1, F2, F3, F4, F5, F6, F7, F8, F9, F10},
  props: {
    rating:{
      type: Array,
      default() {
        return [];
      },
    },
    visible: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  data() {
    return {
      enableFormulas: ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10'],
      fields: [
        {key: 'index', label: '#'},
        {key: 'name', label: 'Підрозділи'},
        {key: 'rate', label: 'Загальна оцінка'},
        // {key: 'action', label: 'Дії', class: 'd-print-none'},
      ],
    }
  },

  watch: {
    rating: function (v) {
      if (v.length) {
        v.forEach(item => {
          if (this.visible || item.errors.length || item.formulas.length) {
            this.fields.push({key: 'action', label: 'Дії', class: 'd-print-none'});
          }
        })
      }
    }
  },

  methods: {
    messageIsHasAsuIdToast(messages) {
      const h = this.$createElement;

      let vNodesMsg = [];

      messages.forEach(function (message) {
        vNodesMsg.push( h(
            'p',
            { class: ['mb-1'] },
            [
              message,
            ]
        ))
      });

      this.$bvToast.toast([vNodesMsg], {
        title: `Проблема з розрахунком`,
        variant: 'warning',
        solid: true,
        autoHideDelay: 2100
      })
    },
  }
}
</script>

<style scoped>

</style>
