<template>
    <b-container id="printMe">
      <b-row class="justify-content-md-between align-items-center pb-3">
        <b-col cols="12" lg="7" class="mb-3 mb-lg-0">
          <span>Дата останнього оновлення <b-badge variant="success">{{ updated }}</b-badge></span>
        </b-col>
        <b-col cols="12" lg="5" class="d-flex justify-content-center justify-content-lg-end">
          <b-button-group>
            <b-button
                variant="outline-primary"
                class="d-block ml-auto d-print-none"
                @click="getForcedUnitRating">
              Оновити
            </b-button>
            <b-button class="d-block ml-auto d-print-none" variant="primary" @click="print">Завантажити</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <TableRating :rating="rating" @getRating="getRating" visible>
        <Header :title="title"/>
      </TableRating>

      <Loader :show="loader"/>
    </b-container>
</template>

<script>
import api from '@/api'
import {API} from '@/api/constants-api';
import Loader from '@/components/base/Loader';
import TableRating from '@/components/TableRating';
import Toasts from '@/mixins/Toasts';
import Header from "@/components/base/Header";

export default {
name: "RatingPage",
  data() {
    return {
      title: 'Рейтинг Факультетів та Інститутів',
      updated: null,
      status: true,
      loader: false,
      rating: [],
    }
  },
  components: {
    Loader,
    TableRating,
    Header
  },
  mixins: [Toasts],
  mounted() {
    this.getRating();
  },
  watch: {
  },

  methods: {
    async getRating() {
      this.loader = true;
      const {data} = await api.get(API.RATING).catch(er => {
        this.loader = false;
        this.ToastError(er.response.data.message);
        console.log(er);
      })
      const { rating, updated  } = data;
      this.rating = rating;
      this.updated = updated;
      this.loader = false;
    },
    async getForcedUnitRating() {
      this.loader = true;
      const {data} = await api.get(API.FORCED_UNIT_RATING).catch(er => {
        console.log(er);
        this.ToastError(er.response.data.message);
        this.loader = false;
      })
      const { rating, updated  } = data;
      this.rating = rating;
      this.updated = updated;
      this.loader = false;
    },
    print() {
      window.print();
    }
  }
}
</script>

<style scoped>

</style>
