<template>
  <b-form @submit.stop.prevent="onSubmit" @reset="onReset">
    <b-row>
      <b-col cols="12" lg="4">
        <b-form-group
          id="input-group-1"
          label="ПІБ студента"
          label-for="fullNameStudent"
        >
          <b-form-input
            id="fullNameStudent"
            v-model="fullNameStudent"
            type="text"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="8">
        <b-form-group
          id="selectSupervisors"
          label="Науковий керівник"
          label-for="selectSupervisors"
        >
          <CSelect multiple :options="supervisorsList" label="full_name" v-model="selectSupervisors"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          id="importTypeStudent"
          label="Тип імпорту студента"
          label-for="importTypeStudent"
        >
          <CSelect multiple :options="importTypeStudent" label="type" v-model="selectImportTypeStudent"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group
          id="statusStudent"
          label="Статус студента"
          label-for="statusStudent"
        >
          <CSelect
            :options="statusList"
            label="label"
            v-model="selectStatusStudent"
            :reduce="(el) => el.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-group
          id="statusSupervisor"
          label="Статус керівника"
          label-for="statusSupervisor"
        >
          <CSelect
            :options="statusList"
            label="label"
            v-model="selectStatusSupervisor"
            :reduce="(el) => el.value"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="6">
        <b-form-group
          id="unitsSupervisor"
          label="Факультет наукового керівника"
          label-for="unitsSupervisor"
        >
          <CSelect :options="units" label="department" v-model="selectUnitSupervisors"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" lg="6">
        <b-form-group
          id="departmentSupervisors"
          label="Кафедра наукового керівника"
          label-for="departmentsSupervisor"
        >
          <CSelect :options="departments" label="department" v-model="selectDepartmentSupervisors"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-button
      variant="outline-primary"
      type="submit"
    >
      <b-icon icon="search"/>
      Пошук
    </b-button>
    <b-button class="ml-2" type="reset" variant="outline-secondary">Очистити</b-button>
  </b-form>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import api from "@/api";
import {API} from "@/api/constants-api";

export default {
  name: "Filters",
  data() {
    return {
      supervisorsList: [],
      selectSupervisors: [],
      fullNameStudent: '',
      importTypeStudent: [],
      selectImportTypeStudent: [],
      units: [],
      selectUnitSupervisors: null,
      departments: [],
      selectDepartmentSupervisors: null,
      statusList: [
        {
          'label': 'Всі',
          'value': null
        },
        {
          'label': 'Знайдені',
          'value': true
        },
        {
          'label': 'Не знайдені',
          'value': false
        }
      ],
      selectStatusStudent: null,
      selectStatusSupervisor: null
    }
  },
  props: {

  },
  watch: {
    selectUnitSupervisors(v) {
      if (v !== null) {
        this.getDepartments(v.id);
      } else {
        this.departments = [];
      }
    }
  },
  components: {
    CSelect
  },
  created() {
    this.getSupervisorsList();
    this.getImportTypeList();
    this.getUnits();
  },
  methods: {
    async getSupervisorsList() {
      const {data} = await api.index(API.SUPERVISORS_LIST).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })
      this.supervisorsList = data
    },

    async getImportTypeList() {
      const {data} = await api.index(API.IMPORT_TYPE_LIST).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })

      this.importTypeStudent = data
    },

    async getUnits() {
      const {data} = await api.index(API.GET_UNITS_LIST).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })

      this.units = data
    },

    async getDepartments(id) {
      const {data} = await api.get(API.GET_DEPARTMENTS_LIST + '/' + id).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })

      this.departments = data
    },

    getParams() {
      return {
        'fullNameStudent': this.fullNameStudent,
        'supervisors': this.selectSupervisors.map((item) => item.id),
        'importType': this.selectImportTypeStudent.map((item) => item.id),
        'unitSupervisors': this.selectUnitSupervisors ? this.selectUnitSupervisors.id : null,
        'departmentSupervisors': this.selectDepartmentSupervisors ? this.selectDepartmentSupervisors.id : null,
        'statusStudent': this.selectStatusStudent,
        'statusSupervisor': this.selectStatusSupervisor,
      }
    },

    onSubmit() {
      this.$emit('submit', this.getParams())
    },

    onReset() {
      this.fullNameStudent = '';
      this.selectSupervisors = [];
      this.selectImportTypeStudent = [];
      this.selectUnitSupervisors = null;
      this.selectDepartmentSupervisors = null;
      this.selectStatusStudent = null;
      this.selectStatusSupervisor = null;
    }
  }
}
</script>

<style scoped>

</style>
