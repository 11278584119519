<template>
  <div>
    <b-table hover :items="supervisors" :fields="fields" no-local-sorting head-variant="primary" show-empty stacked="md">
      <template #cell(id)="row">
        {{ meta.from + row.index }}
      </template>

      <template #empty="scope">
        <h6 class="text-center my-2">Немає записів для показу</h6>
      </template>

      <template #table-caption v-if="meta && meta.total !== 0">
        <div class="text-right">
          Показано від {{meta.from}} до {{meta.to}} із {{meta.total}} записів
        </div>
      </template>
    </b-table>

    <b-pagination
        v-if="meta"
        v-model="current_page"
        class="justify-content-center"
        :total-rows="meta.total"
        :per-page="meta.per_page"
    ></b-pagination>

  </div>
</template>

<script>
export default {
  name: "TableSupervisors",
  props: {
    supervisors: {
      type: Array,
      default() {
        return [];
      },
    },
    meta: {
      type: Object,
      default() {
        return null
      }
    },
  },
  data() {
    return {
      current_page: 1,
      fields: [
        {key: 'id', label: '#'},
        {key: 'full_name', label: 'ПІБ'},
        {key: 'department', label: 'Кафедра'},
        {key: 'unit', label: 'Факультет/Інститут'},
        {key: 'position', label: 'Посада'},
        {key: 'import_type', label: 'Тип імпорта', sortable: true},
      ],
    }
  },
  watch: {
    current_page: function (currentPage) {
      this.$emit('getSupervisors', currentPage);
    }
  },
  methods: {
    messageIsHasAsuIdToast(messages) {
      const h = this.$createElement;

      let vNodesMsg = [];

      messages.forEach(function (message) {
        vNodesMsg.push( h(
            'p',
            { class: ['mb-1'] },
            [
              message,
            ]
        ))
      });

      this.$bvToast.toast([vNodesMsg], {
        title: `Проблема з пошуком`,
        variant: 'warning',
        solid: true,
        autoHideDelay: 10000
      })
    },
  },
}
</script>

<style scoped>

</style>