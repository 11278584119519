<template>
  <b-container>
    <b-row class="justify-content-md-center">
      <b-col lg="8">
        <b-card
          header="Змінити пароль"
          class="m-auto"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
        >
          <b-alert variant="success"
                   dismissible
                   :show="dismissCountDown"
                   @dismissed="dismissCountDown=0"
                   @dismiss-count-down="countDownChanged"
          >
            {{success.message}} {{ dismissCountDown }}
          </b-alert>

          <b-alert variant="danger"
                   dismissible
                   v-model="Object.keys(errors).length ? 5 : 0"
          >
            <p class="mb-0" v-for="error in errors" v-if="error" >{{ error[0] }}</p>
          </b-alert>

          <validation-observer ref="observer" v-slot="{ handleSubmit}">
            <b-form ref="form" @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="Старий пароль"
                :rules="{ required: true}"
                v-slot="validationContext"
              >
                <b-form-group id="password-group"
                              label="Старий пароль:"
                              label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="details.password"
                    type="password"
                    placeholder="Введіть пароль"
                    :state="GlobalGetValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="новий пароль"
                :rules="{ required: true, min: 8}"
                v-slot="validationContext"
                :vid="details.new_password_confirmation"
              >
                <b-form-group id="new-password-group"
                              label="Новий пароль:"
                              label-for="new-password"
                >
                  <b-form-input
                    id="new-password"
                    v-model="details.new_password"
                    type="password"
                    placeholder="Введіть пароль"
                    :state="GlobalGetValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="повторіть новий пароль"
                :rules="{ required: true, min: 8, confirmed: details.new_password}"
                v-slot="validationContext"
              >
                <b-form-group id="new-password-confirmation-group"
                              label="Повторіть новий пароль:"
                              label-for="new-password-confirmation"
                >
                  <b-form-input
                    id="new-password-confirmation"
                    v-model="details.new_password_confirmation"
                    type="password"
                    placeholder="Введіть пароль"
                    :state="GlobalGetValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button type="submit" variant="primary" class="mr-2">Зберегти</b-button>
              <b-button type="button" variant="light" @click="reset">Очистити</b-button>

            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from '@/api'
import {API} from "@/api/constants-api";
import { mapGetters } from "vuex";

export default {

  name: "ResetPasswordPage",
  data() {
    return {
      details: {
        password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      success: {},
      dismissCountDown: 0,
    }
  },
  computed: {
    ...mapGetters(["errors"])
  },
  methods: {
    onSubmit() {
      api.patch(API.UPDATE_PASSWORD, null, this.details)
        .then( (response) =>
        {
          if (response.status === 200) {
            this.success = response.data;
            this.dismissCountDown = 3;
            this.reset();
          }
        })
        .catch(() => this.reset())
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    reset() {
      this.details.password = '';
      this.details.new_password = '';
      this.details.new_password_confirmation = '';
      this.$nextTick(() => this.$refs.observer.reset());
    }
  }
}
</script>

<style scoped>

</style>
