export const LOGO = require('@/assets/images/logo-sumdu.svg') //TODO check is exist file

export const NAME_APP = "Студентська наука СумДУ"

export const LETTER_F = "&#402;"

export const ROLES = {
  ID: {
    'admin': 1,
    'moderator': 2,
  },
}
