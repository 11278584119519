<template>
  <div>
    <p>
      <b>Оцінки</b>
    </p>

    <ListMarks :marks="marks"></ListMarks>

    <div class="d-flex justify-content-end">
      <b-button variant="primary" class="mt-3" @click="save()">Зберегти</b-button>
    </div>

  </div>
</template>

<script>
import ListMarks from "@/components/EditStudent/ListMarks";
export default {
  name: "EditMarks",
  components: {ListMarks},
  props: ['marks', 'supervisorId'],
  methods: {
    save() {
      this.$emit('save')
    }
  }
}
</script>

<style scoped>

</style>
