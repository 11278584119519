<template>
  <div class="formula">
    <span>F<sub>9і</sub></span>
    <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->
    <span class="bracket">&#40;&nbsp;</span> <!--Скобка откр-->
    <div class="d-flex flex-column align-items-center">
      <span>
        Пр<sub>і</sub><sup>м</sup>
      </span>
      <span class="divider"></span>
      <span>
        К<sub>НППі(&#8805;с)у</sub><sup>0,7</sup>
      </span>
    </div>

    <span>&nbsp;&#43;&nbsp;</span> <!--Знак суммы-->

    <div class="d-flex flex-column align-items-center">
      <span>
        Пр<sub>і</sub><sup>н</sup>
      </span>
      <span class="divider"></span>
      <span>
        Кн<sub>і</sub>
      </span>
    </div>
    <span class="bracket">&nbsp;&#41;</span> <!--Скобка закр-->
    <span>&nbsp;&#8727;&nbsp;</span> <!--Знак умножения-->
    <span>ƒ<sub>9</sub></span>
    <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->

    <template v-if="options">
      <span class="bracket">&#40;&nbsp;</span> <!--Скобка откр-->

      <fraction :numerator="options.numerator1" :denominator="options.denominator1"></fraction>
      <span>&nbsp;&#43;&nbsp;</span> <!--Знак суммы-->
      <fraction :numerator="options.numerator2" :denominator="options.denominator2"></fraction>

      <span class="bracket">&nbsp;&#41;</span> <!--Скобка закр-->


      <span>&nbsp;&#8727;&nbsp;</span> <!--Знак умножения-->
      <span>{{options.multiplier}}</span>
      <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->

      {{options.result}}
    </template>
  </div>
</template>

<script>
import Fraction from "@/components/formulas/Fraction";
export default {
  name: "F9",
  components: {Fraction},
  props: ['options'],
}
</script>

<style scoped>

</style>
