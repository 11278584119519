<template>
  <b-container>
    <Header class="" title="Редагування студента"/>

    <template v-if="student">
      <b-alert :show="student.error.length" variant="warning">{{student.error}}</b-alert>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" header-bg-variant="primary" class="p-1" role="tab">
            <div v-b-toggle.info-student class="p-2">
              <HeaderCollapseWithArrow>Інформація про студента</HeaderCollapseWithArrow>
            </div>
          </b-card-header>
          <b-collapse id="info-student" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text v-for="(name, key) in titleStudent" :key="key">
                <InfoStudent :title="name" :value="student[key]"/>
              </b-card-text>
              <b-card-text>
                <InfoStudent title="Загальна оцінка" :value="totalMark(student.marks)"/>
              </b-card-text>
              <EditMarks v-if="student.marks && student.marks.length" :marks="student.marks" @save="saveMarks(student.marks)"></EditMarks>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <div v-if="student.supervisors" class="accordion" role="tablist" v-for="supervisor in student.supervisors" :key="supervisor.id">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" header-bg-variant="primary" class="p-1" role="tab">
            <div v-b-toggle="'supervisor-' + supervisor.id" class="p-2">
                <HeaderCollapseWithArrow>
                  Керівник: {{supervisor.full_name}}
                  <b-button
                    v-if="supervisor.error.length"
                    class="ml-3"
                    v-b-tooltip
                    title="Проблема з пошуком"
                    variant="warning"
                    size="sm"
                    @click="messageIsHasAsuIdToast([supervisor.error])"
                  >
                    <b-icon class="text-white" icon="exclamation-circle-fill" font-scale="1"/>
                  </b-button>
                </HeaderCollapseWithArrow>
            </div>
          </b-card-header>
          <b-collapse :id="'supervisor-' + supervisor.id" :accordion="'my-accordion-' + supervisor.id" role="tabpanel">
            <b-card-body>
              <b-card-text class="text-center" v-if="!student.supervisors">
                Данні відсутні
              </b-card-text>
              <template v-else>
                <p class="row">
                  <b-col cols="12" md="10">
                    <b-card-text v-for="(name, key) in titleSupervisor" :key="key">
                      <InfoStudent :title="name" :value="supervisor[key]"/>
                    </b-card-text>
                    <b-card-text>
                      <InfoStudent title="Загальна оцінка" :value="totalMark(supervisor.marks)"/>
                    </b-card-text>
                  </b-col>
<!--                  <b-col cols="12" md="2" class="mt-md-0 mt-3">-->
<!--                    <b-button-group>-->
<!--                      <b-button-->
<!--                        v-b-tooltip-->
<!--                        variant="outline-primary"-->
<!--                        @click="edit()"-->
<!--                        title="Редагувати"-->
<!--                      >-->
<!--                        <b-icon icon="pencil" font-scale="1"/>-->
<!--                      </b-button>-->
<!--                      <b-button-->
<!--                        v-b-tooltip-->
<!--                        variant="outline-danger"-->
<!--                        title="Видалити"-->
<!--                      >-->
<!--                        <b-icon icon="trash" font-scale="1"/>-->
<!--                      </b-button>-->
<!--                    </b-button-group>-->
<!--                  </b-col>-->
                </p>
                <EditMarks v-if="supervisor.marks && supervisor.marks.length" :marks="supervisor.marks" @save="saveMarks(supervisor.marks, supervisor.id)"></EditMarks>
              </template>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </template>

    <template v-else-if="!student && !loader">
      <NotFoundPage message="Студента не знайдено" route="Import" btn-label="Назад"></NotFoundPage>
    </template>


    <form ref="form" @submit.stop.prevent="edit()">
      <b-modal v-model="editModal"
               id="modal-prevent-closing"
               title="Редагування"
               header-bg-variant="primary"
               header-text-variant="light"
               cancel-title="Закрити"
               ok-title="Зберегти"
               @ok.prevent="edit()"
               @close="reset"
               size="xl"
      >
        <c-select v-model="select" :options="arraySelect" :label="'name'"></c-select>
      </b-modal>
    </form>


    <Loader :show="loader"/>

  </b-container>
</template>

<script>
import api from "@/api";
import {API} from "@/api/constants-api";

import Loader from "@/components/base/Loader";
import Toasts from "@/mixins/Toasts";
import InfoStudent from "@/components/EditStudent/InfoStudent";
import HeaderCollapseWithArrow from "@/components/EditStudent/HeaderCollapseWithArrow";
import ListMarks from "@/components/EditStudent/ListMarks";
import EditMarks from "@/components/EditStudent/EditMarks";
import Header from "@/components/base/Header";
import NotFoundPage from "@/views/pages/NotFoundPage";
import CSelect from "@/components/Forms/CSelect";

export default {
name: "EditStudentSupervisorExcel",
  components: {NotFoundPage, Header, EditMarks, ListMarks, HeaderCollapseWithArrow, InfoStudent, Loader, CSelect},
  data() {
    return {
      select: null,
      arraySelect: [
        {id: 1, name: "first"},
        {id: 2, name: "second"},
        {id: 3, name: "three"},
      ],
      editModal: false,
      student: null,
      titleStudent: {
        full_name: 'ПІБ',
        group: 'Група',
        course: 'Курс',
        degree: 'Наукова ступінь'
      },
      titleSupervisor: {
        full_name: 'ПІБ',
        department: 'Кафедра',
        unit: 'Факультет'
      },
      loader: false
    }
  },

  watch: {
    select(v) {
      console.log(v);
    }
  //   student: {
  //     handler: function (v) {
  //        console.log(v)
  //     },
  //     deep: true
  //   }
  },
  created() {
    this.getStudent();
  },
  mixins: [Toasts],
  methods: {
    async getStudent() {
      this.loader = true;
      const {data} = await api.get(API.GET_STUDENT + '/'+ this.$route.params.id).catch((error) => {
        console.log('error',error.response);
        this.loader = false;
        this.ToastError(error.response.data.message);
      })
      this.student = data;
      console.log(data);
      this.loader = false;
    },
    messageIsHasAsuIdToast(messages) {
      const h = this.$createElement;

      let vNodesMsg = [];

      messages.forEach(function (message) {
        vNodesMsg.push( h(
          'p',
          { class: ['mb-1'] },
          [
            message,
          ]
        ))
      });

      this.$bvToast.toast([vNodesMsg], {
        title: `Проблема з пошуком`,
        variant: 'warning',
        solid: true,
        autoHideDelay: 10000
      })
    },
    async saveMarks(marks, supervisorId = null) {
      const setMarks = this.getMarks(marks);

      const request = {
        student_id: parseInt(this.$route.params.id),
        marks: setMarks,
        supervisor_id: supervisorId,
      };

      const {data, status} = await api.post(API.SAVE_STUDENT_MARKS, request ).catch((error) => {
        console.log('error',error.response);
        this.loader = false;
        this.ToastError(error.response.data.message);
      })

      if (status === 200) {
        this.ToastSuccess(data.message);
        this.getStudent();
      }
    },
    getMarks(marks) {
      return marks.filter((mark) => {
        if (mark.status === true) {
          return mark
        }
      })
    },
    totalMark(marks = []) {
      let mark = 0;

      if (!marks.length) {
        return mark;
      }

      marks.forEach(function (item) {
        if (item.status) {
          mark += item.mark;
        }
      })

      return mark;
    },
    edit() {
      this.editModal = true
    },
    deletedItem() {},
    reset() {

    }
  }
}
</script>

<style scoped>

</style>
