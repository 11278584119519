<template>
  <b-alert
          :variant="options.variant"
          :class="options.class"
          dismissible
          fade
          :show="options.count"
          @dismissed="options.count=0"
          @dismiss-count-down="countDownChanged"
  >
    {{ options.message }} {{ options.count }}
  </b-alert>
</template>

<script>
export default {
name: "Alert",
  data () {
   return {
     options: {
       count: 0,
       message: '',
       class: '',
       variant: 'success'
     }
   }
  },
  props: {
    optionsProps: {}
  },
  watch: {
    optionsProps: function (val) {
      this.options = { ...this.options,...val}
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.options.count = dismissCountDown
    },
  }
}
</script>

<style scoped>
.top-left,
.top-right,
.bottom-left,
.bottom-right {
  z-index: 1;
  position: absolute;
  min-width: 320px;
}
  .top-left {
    top: 10px;
    left: 10px;
  }
  .top-right {
    top: 10px;
    right: 10px;
  }
  .bottom-left {
    bottom: 0;
    left: 10px;
  }
  .bottom-right {
    bottom: 0;
    right: 10px;
  }
</style>
