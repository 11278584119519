export default {
  methods: {
    ToastSuccess(messages, title= 'Успіх', variant = 'success') {
      this.$bvToast.toast([messages], {
        title,
        variant,
        solid: true
      });
    },
    ToastError(messages, title= 'Помилка', variant = 'danger') {
      this.$bvToast.toast([messages], {
        title,
        variant,
        solid: true
      });
    },
  }
};
