<template>
  <div>
    <b-container fluid class="mt-5">
      <b-table hover :items="students" :fields="fields" head-variant="primary" show-empty stacked="lg" >
        <template #cell(id)="row">
          {{ meta.from + row.index }}
        </template>

        <template #row-details="row">
          <div class="accordion" role="tablist" v-if="row.item.supervisors.length">
            <b-card
              header="Керівники"
              header-bg-variant="white"
              border-variant="primary"
              header-border-variant="primary"
              header-text-variant="primary"
            >
              <b-list-group v-for="supervisor in row.item.supervisors" :key="supervisor.id">
                <b-list-group-item
                  class="header-supervisor"
                  block v-b-toggle="'accordion-'+supervisor.id + '-' + row.item.id"
                >
                  <span>
                    {{supervisor.full_name}}
                    <span class="text-left text-info small">({{supervisor.department}})</span>
                  </span>
                  <div class="d-flex">Оцінка: &nbsp;<h5 class="mb-0"><b-badge variant="primary">{{supervisor.total_marks}}</b-badge></h5></div>
                </b-list-group-item>
                <b-collapse :id="'accordion-' + supervisor.id + '-' + row.item.id " accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <ListMarks flush :marks="supervisor.marks"/>
                  </b-card-body>
                </b-collapse>
              </b-list-group>
            </b-card>
          </div>

          <b-card no-body class="mt-1"
                  border-variant="primary"
                  v-if="row.item.marks.length"
          >
            <b-card-header header-tag="header" class="p-1  bg-white border-bottom border-primary">
              <b-list-group flush >
                <b-list-group-item
                  class="header-supervisor"
                  v-b-toggle="'accordion-student-' + row.item.id"
                >
                  Студент
                  <div class="d-flex">Оцінка: &nbsp;<h5 class="mb-0"><b-badge variant="primary">{{sumMarks(row.item.marks)}}</b-badge></h5></div>
                </b-list-group-item>
              </b-list-group>
            </b-card-header>
            <b-collapse :id="'accordion-student-' + row.item.id ">
              <b-card-body>
                <ListMarks flush :marks="row.item.marks"></ListMarks>
              </b-card-body>
            </b-collapse>
          </b-card>

        </template>

        <template #cell(actions)="row">
          <b-button
            v-b-tooltip
            v-if="row.item.total_marks !== 0"
            class="mt-1 mr-1"
            variant="outline-info"
            size="sm"
            @click="row.toggleDetails"
            title="Докладніше">
              <b-icon icon="eye" font-scale="1"/>
          </b-button>
          <b-button
            v-if="edit"
            v-b-tooltip class="mt-1 mr-1"
            variant="outline-primary"
            size="sm"
            :to="{name: 'EditStudentSupervisorExcel', params: {id: row.item.id}}"
            title="Редагувати">
              <b-icon icon="pencil" font-scale="1"/>
          </b-button>
<!--          <b-button-->
<!--            v-if="deleted"-->
<!--            v-b-tooltip-->
<!--            variant="outline-danger"-->
<!--            size="sm" @click="deletedItem(row.item)"-->
<!--            title="Видалити"-->
<!--          >-->
<!--            <b-icon icon="trash" font-scale="1"/>-->
<!--          </b-button>-->
          <b-button v-if="row.item.messages_is_has_asu_id.length"
            class="mt-1 mr-1"
            v-b-tooltip
            title="Проблема з пошуком"
            variant="warning"
            size="sm"
            @click="messageIsHasAsuIdToast(row.item.messages_is_has_asu_id)">
              <b-icon class="text-white" icon="exclamation-circle-fill" font-scale="1"/>
          </b-button>
        </template>

        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>

        <template #table-caption v-if="meta && meta.total !== 0">
          <div class="text-right">
            Показано від {{meta.from}} до {{meta.to}} із {{meta.total}} записів
          </div>
        </template>

      </b-table>
      <b-pagination
        v-if="meta"
        v-model="current_page"
        class="justify-content-center"
        :total-rows="meta.total"
        :per-page="meta.per_page"
      ></b-pagination>
    </b-container>

    <b-modal cancel-title="Ні" ok-title="Так" cancel-variant="danger" id="deleted" title="Видалення" @ok.prevent="onDelete">
      <p class="my-4">Ви точно хочете видалити запис  {{ selectItem.id }}</p>
    </b-modal>

  </div>
</template>

<script>
import ListMarks from "@/components/base/ListMarks";
import Toasts from "@/mixins/Toasts";

export default {
  name: "TableStudents",
  props: {
    students: {
      type: Array,
      default(){ return []}
    },
    meta: {
      type: Object,
      default(){return null}
    },
    edit: {
      type: Boolean,
      default(){return false}
    },
    deleted: {
      type: Boolean,
      default(){return false}
    }
  },
  data() {
    return {
      selectItem: {},
      current_page: 1,

      fields: [
        {key: 'id', label: 'Id'},
        {key: 'full_name', label: 'ПІБ студента'},
        {key: 'group', label: 'Група'},
        {key: 'course', label: 'Курс'},
        {key: 'unit', label: 'Факультет/Інститут'},
        {key: 'import_type', label: 'Тип імпорта'},
        {key: 'total_marks', label: 'Загальна оцінка', class: 'text-lg-center'},
        {key: 'actions', label: 'Дії'},
      ],
    }
  },
  components: {
    ListMarks,
  },
  mixins: [Toasts],

  watch: {
    current_page: function (currentPage) {
      this.$emit('getStudents', currentPage);
    }
  },

  methods: {
    messageIsHasAsuIdToast(messages) {
      const h = this.$createElement;

      let vNodesMsg = [];

      messages.forEach(function (message) {
        vNodesMsg.push( h(
          'p',
          { class: ['mb-1'] },
          [
            message,
          ]
        ))
      });

      this.$bvToast.toast([vNodesMsg], {
        title: `Проблема з пошуком`,
        variant: 'warning',
        solid: true,
        autoHideDelay: 10000
      })
    },

    sumMarks(marks) {
      return marks.reduce((a, b) => +a + +b.mark, 0);
    },

    onDelete() {
      this.$nextTick(() => {
        this.$bvModal.hide('deleted')
      })

      this.reset();
    },

    deletedItem(item) {
      this.selectItem = {...item};
      this.$bvModal.show('deleted')
    },

    reset() {
      this.selectItem = {}
    }
  }
}
</script>

<style scoped lang="scss">
.header-supervisor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: $base-bg;

  &:first-child {
    border-radius: 0;
  }

  &.not-collapsed {
    border-bottom: 3px solid $base-bg;
  }
}

</style>
