<template>
  <b-container>
    <Alert :options-props="success"/>

    <b-overlay :show="showTable" spinner-variant="primary">
      <b-table hover :items="multipliers" :fields="fields" head-variant="primary">
        <template #cell(id)="row">
          {{ row.item.id }}
        </template>

        <template #cell(actions)="row">
          <b-button variant="outline-primary" size="sm" @click="edit(row.item)">
            <b-icon icon="pencil" font-scale="1"/>
          </b-button>
        </template>
      </b-table>
    </b-overlay>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">



      <form ref="form" @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-modal v-model="editModal"
                 id="modal-prevent-closing"
                 centered
                 title="Редагування"
                 header-bg-variant="primary"
                 header-text-variant="light"
                 cancel-title="Закрити"
                 ok-title="Зберегти"
                 @ok.prevent="handleSubmit(onSubmit)"
                 @close="reset"
        >
          <p class="my-2">Змінити коефіцієнт {{ editItem.name }}</p>
          <validation-provider
            name="індекс"
            :rules="{ required: true, min: 1, max_value:1}"
            v-slot="validationContext"
          >
            <b-form-group id="example-input-group-1" label="" label-for="example-input-1">
              <b-form-input
                v-mask="'#.###'"
                id="example-input-1"
                name="example-input-1"
                v-model="editItem.multiplier"
                :state="GlobalGetValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-modal>
      </form>
    </validation-observer>
  </b-container>
</template>

<script>
import api from '@/api'
import {API} from "@/api/constants-api";
import {LETTER_F} from "@/utils/constants"

import Alert from '@/components/base/Alert'

export default {
  name: "ActivityLevels",
  components: {
    Alert
  },
  data() {
    return {
      name: 'sss',
      symbol: LETTER_F,
      fields: [
        {key: 'id', label: '№'},
        {key: 'name', label: 'Назва'},
        {key: 'multiplier', label: 'Коефіцієнт'},
        {key: 'actions', label: 'Дії'},
      ],
      multipliers: [],
      editModal: false,
      editItem: {},
      showTable: true,
      success: {},
      errors: {}
    }
  },
  mounted() {
    this.getMultipliers();
  },
  methods: {
    async getMultipliers() {
      const {data} = await api.get(API.ACTIVITY_LEVELS)
      if (data) {
        this.multipliers = data.data;
        this.showTable = false
      }
    },
    edit(item) {
      this.editModal = true
      this.editItem = {...item}
    },
    onSubmit() {
      api.patch(API.ACTIVITY_LEVELS,this.editItem.id, this.editItem).then( (res) => {
        this.multipliers.map( el => { if(el.id === this.editItem.id) el.multiplier = this.editItem.multiplier})
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })

        this.success = {
          message: res.data.message,
          count: 3,
          class: 'bottom-right',
        }
      })
    },
    reset() {
      this.editModal = false
      this.editItem = {}
    }
  }
}
</script>

<style lang="scss">
</style>
