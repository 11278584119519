<template>
  <div class="formula">
    <span>F<sub>8і</sub></span>
    <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->
    <div class="d-flex flex-column align-items-center">
      <span>
        К<sub>НПП(&#8805;с)і</sub>
      </span>
      <span class="divider"></span>
      <span>
        К<sub>НПП(&#8805;с)у</sub>
      </span>
    </div>
    <span>&nbsp;&#8727;&nbsp;</span> <!--Знак умножения-->
    <span>ƒ<sub>8</sub></span>
    <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->

    <template v-if="options">
      <fraction :numerator="options.numerator1" :denominator="options.denominator1"></fraction>
      <span>&nbsp;&#8727;&nbsp;</span> <!--Знак умножения-->
      <span>{{options.multiplier}}</span>
      <span>&nbsp;&#9868;&nbsp;</span> <!--Знак равенства-->

      {{options.result}}
    </template>
  </div>
</template>

<script>
import Fraction from "@/components/formulas/Fraction";
export default {
  name: "F8",
  components: {Fraction},
  props: ['options'],
}
</script>

<style scoped>

</style>
