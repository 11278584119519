<template>
  <div>
    <b-button variant="primary" class="cbutton" @click="() => this.$emit('click')">
      <slot>
        <b-icon icon="plus" font-scale="1.6"/>
      </slot>
    </b-button>
  </div>

</template>

<script>
export default {
  name: "ActionBtn",
  props: [
    'size'
  ]
}
</script>

<style scoped>
  .fixed {
    position: fixed;
  }
  .bottom-left {
    bottom: 15px;
    left: 20px;
  }
  .bottom-right {
    bottom: 15px;
    right: 20px;
  }
  .rounded .cbutton {
    border-radius: 50%;
    padding: 20px;
  }
  .cbutton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
  }
</style>
