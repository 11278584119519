<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <form ref="form" @submit.stop.prevent="handleSubmit(submit)">
      <b-modal
        v-model="dialog"
        id="modal-create-constant"
        centered
        title="Створити показник"
        header-bg-variant="primary"
        header-text-variant="light"
        cancel-title="Закрити"
        ok-title="Зберегти"
        size="xl"
        @ok.prevent="handleSubmit(submit)"
        @hidden="$emit('reset')"
      >
        <validation-provider
          name="Факультет/Інститут/Кафедра"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            id="unit-group"
            label="Факультет/Інститут/Кафедра"
            label-for="unit-input"
          >
            <CSelect
              :options="fields.units"
              id="unit-input"
              label="department"
              v-model="unit"
              :invalid="
                validationContext.invalid && validationContext.validated
              "
              :valid="validationContext.valid && validationContext.validated"
              :reduce="(option) => option"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row cols="1" cols-md="2" cols-lg="3" v-if="unit">
          <b-col
            v-for="type in fields.constants.filter(
              (el) => el.type === unit.unit_type
            )"
            :key="type.id"
          >
            <validation-provider
              :name="'Показник ' + type.label"
              rules="required|double:2,dot|max:15"
              v-slot="validationContext"
            >
              <b-input-group class="mb-3">
                <template #prepend>
                  <b-input-group-text v-html="type.label"></b-input-group-text>
                </template>
                <b-form-input
                  trim
                  v-model="items[type.id]"
                  required
                  :state="GlobalGetValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback v-html="validationContext.errors[0]" />
              </b-input-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-modal>
    </form>
  </validation-observer>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import { VueEditor } from "vue2-editor";
import api from "@/api";
import { API, ALLOWED_REQUEST_PARAMETERS } from "@/api/constants-api";

export default {
  name: "mCreateConstant",
  components: { CSelect, VueEditor },
  data() {
    return {
      dialog: this.open,
      unit: null,
      items: [],
    };
  },
  props: {
    open: {
      default: false,
    },
    fields: {
      required: true,
    },
  },
  watch: {
    open(v) {
      this.dialog = v;
    },
  },
  methods: {
    submit() {
      let indicators = [];
      const _this = this;

      this.items.forEach(function (value, key) {
        indicators.push({
          constant_type_id: key,
          unit_id: _this.unit.id,
          unit: _this.unit.department,
          value,
          unit_type: _this.unit.unit_type,
        });
      });

      this.$emit("submit", indicators);
    },
  },
};
</script>

<style scoped lang="scss"></style>
