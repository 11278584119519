<template>
  <b-container fluid v-if="breadCrumbs" class="mt-3">
    <b-breadcrumb :items="breadCrumbs" class="bg-transparent"></b-breadcrumb>
  </b-container>
</template>

<script>
export default {
  name: "BreadCrumbs",
  computed: {
    breadCrumbs() {
      if (typeof this.$route.meta.breadCrumb === "function") {
        return this.$route.meta.breadCrumb.call(this, this.$route);
      }
      return this.$route.meta.breadCrumb;
    },
  },
}
</script>

<style scoped>

</style>
