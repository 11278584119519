import axios from 'axios';
import vuexStore from '@/store';
import router from '@/router';
import jsonToQuery from 'json-to-query-string'

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + 'api/',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});
// api.interceptors.request.use(
//   config => config,
//   error => Promise.reject(error),
// );
//
// api.interceptors.response.use(
//   async resp => resp,
//   async err => {
//     // if (err.response.data.message === 'Unauthenticated.') {
//     //   vuexStore.commit('auth/LOGOUT');
//     //   router.push({ name: 'Login' });
//     // }
//     // return Promise.reject(err);
//   },
// );
api.interceptors.response.use(
  response => {
    if (response.config.showLoader) {
      vuexStore.dispatch('loader/hide');
    }

    return response;
  },

  error => {
    let response = error.response;

    if (response.config.showLoader) {
      vuexStore.dispatch('loader/hide');
    }

    switch (error.response.status) {
      case 400: {
        console.error(error.response.statusText);
        break;
      }
      case 401: {
        vuexStore.commit("auth/setUserData", null);
        localStorage.removeItem("authToken");
        vuexStore.commit("setErrors", { error: error.response.data.message });
        router.push({ name: "Login" });
        break;
      }
      case 403: {
        router.push({ name: 'Forbidden'}); // ToDo create page and component
        break;
      }
      case 404: {
        router.push({ name: "NotFoundPage" });
        break;
      }
      case 405: {
        vuexStore.commit("setErrors", error.response.data.message);
        break;
      }
      case 422: {
        console.log('this error', error.response)
        vuexStore.commit("setErrors", error.response.data.errors); //TODO: this error preview
        break;
      }
      case 500: {
        vuexStore.commit("setErrors", { error: error.response.data.message });
        console.error(error.response.data.message);
        break;
      }
      case 503: {
        router.push({ name: "MaintenanceMode" }); //TODo new create route and template
        break;
      }
      default: {
        return Promise.reject(error);
      }
    }


    // if (error.response.status === 400) {
    //   console.error(error.response.statusText);
    // } else if (error.response.status === 422) {
    //   console.log('this error')
    //   vuexStore.commit("setErrors", error.response.data.errors); //TODO: this error preview
    // } else if (error.response.status === 401) {
    //   vuexStore.commit("auth/setUserData", null);
    //   localStorage.removeItem("authToken");
    //   router.push({ name: "Login" });
    // } else if (error.response.status === 404) {
    //   router.push({ name: "NotFoundPage" });
    // } else if(error.response.status === 500) {
    //   console.error(error.response.statusText);
    // } else {
    //     return Promise.reject(error);
    // }
    return Promise.reject(error); //default error
  }
);
api.interceptors.request.use(function(config) {
  config.headers.common = {
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    "Content-Type": "application/json",
    Accept: "application/json"
  };

  if (config.showLoader) {
    vuexStore.dispatch('loader/show');
  }

  return config;
},
  error => {
    if (error.config.showLoader) {
      vuexStore.dispatch('loader/hide');
    }
    return Promise.reject(error);
  }
);

// const setHeader = (name, value) => api.defaults.headers.common[name] = value;

const get = async (resource, data = false, configs = null) => data ? await api.get(`${resource}?${jsonToQuery(data)}`, configs) : await api.get(resource, configs);
const post = async (resource, data) => await api.post(resource, data);
const put = async (resource, data) => await api.put(resource, data);
const patch = async (resource, id = null ,data = null) => id ? await api.patch(`${resource}/${id}`, data) : await api.patch(resource, data);
const show = async (resource, id, configs = null) => await api.get(`${resource}/${id}`, configs);

const index = async (resource) => await api.get(resource);
const store = async (resource, data) => await api.post(resource, data);
const update = async (resource, id, data) => await api.put(`${resource}/${id}`, data);
const destroy = async (resource, id) => await api.delete(`${resource}/${id}`);

export default {
  get,
  post,
  put,
  index,
  show,
  store,
  update,
  destroy,
  patch,
  // setHeader,
};
