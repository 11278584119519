<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-between">
        <b-col col lg="7">
          <b-row>
            <b-col col lg="9">
              <b-form-file v-model="file" browse-text="Оберіть" placeholder="Оберіть файл Excel" accept=".xlsx,.xls"></b-form-file>
            </b-col>
            <b-col col lg="2">
              <b-button @click="uploadExcelFile" variant="outline-success" :disabled="!file" >Імпортувати</b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="5">
          <b-button class="d-block ml-auto"
                    :disabled="!students"
                    variant="outline-primary"
                    @click="exportFile"
          >
            Експортувати таблицю
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <TableStudents :students="students" :meta="meta" @getStudents="getStudents" edit deleted/>

    <Loader :show="loader"/>

  </div>
</template>

<script>
import api from '@/api'
import {API} from "@/api/constants-api";

import TableStudents from "@/components/TableStudents";
import Loader from "@/components/base/Loader";
import Toasts from "@/mixins/Toasts";

export default {
  name: "PageImport",
  data() {
    return {
      file: null,
      editModal: false,
      selectItem: {},
      loader: false,
      meta: null,
      fields: [
        {key: 'id', label: 'id'},
        {key: 'full_name', label: 'ПІБ студента'},
        {key: 'group', label: 'Група'},
        {key: 'course', label: 'Курс'},
        {key: 'unit', label: 'Факультет/Інститут'},
        // {key: 'supervisor_full_name', label: 'ПІБ керівника'},
        // {key: 'supervisor_position', label: 'Посада керівника'},
        {key: 'total_marks', label: 'Загальна оцінка', class: 'text-center'},
        {key: 'actions', label: 'Дії'},
      ],
      fieldsSmall: [
        {key: 'id', label: 'id'},
        {key: 'student_full_name', label: 'ПІБ студента'},
        {key: 'group', label: 'Група'},
        {key: 'course', label: 'Курс'},
        {key: 'unit', label: 'Факультет/Інститут'},
        {key: 'supervisor_full_name', label: 'ПІБ керівника'},
        {key: 'supervisor_position', label: 'Посада керівника'}
      ],
      students: [],
    }
  },
  components: {
    Loader,
    TableStudents
  },
  mixins: [Toasts],
  created() {
    this.getStudents();
  },
  methods: {
    async uploadExcelFile() {
      this.loader = true;

      let formData = new FormData();
      formData.append('excel', this.file);
      const {data, status} = await api.post(API.UPLOAD_EXCEL_FILE, formData).catch(er => {
        this.loader = false;
        if (er.response === undefined) {
          this.ToastError("Проблеми з файлом!");
        }
        this.ToastError(er.response.data.message);
      })
      if (status === 200) {
        this.getStudents();
        this.ToastSuccess(data.message);
      }

      this.file = null;
    },

    async getStudents(page = 1) {
      this.loader = true;
      const {data} = await api.get(API.GET_STUDENTS_WITH_FILE, {page: page}).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
        this.loader = false;
      })

      const { data:students, meta  } = data;

      this.students = this.GlobalCheckedIsHasAsuId(students);
      this.meta = meta;
      this.loader = false;
    },

    exportFile(){
      this.loader = true;

      api.get(API.EXPORT_STUDENTS_WITH_EXCEL, {}, {responseType: 'arraybuffer'}).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'students-excel.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        this.loader = false;
      }).catch((error) => {
        this.loader = false;
        this.ToastError(error.response.data.message);
      })
    },
  }
}
</script>

<style scoped lang="scss">
  .header-supervisor {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-color: $base-bg;

    &:first-child {
      border-radius: 0;
    }

    &.not-collapsed {
      border-bottom: 3px solid $base-bg;
    }
  }

</style>
