<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-between">
        <b-col col lg="7">
          <div class="text-center">
            <b-button variant="light">
              Студентів не знайдено
              <b-badge variant="warning">{{ countNotIdentifiedStudents }}</b-badge>
            </b-button>
            <b-button variant="light" :to="{name: 'Supervisors'}">
              Керівників не знайдено
              <b-badge variant="warning">{{ countNotIdentifiedSupervisors }}</b-badge>
            </b-button>
          </div>
        </b-col>
        <b-col col lg="5">
          <b-button-group>
            <b-button
              :disabled="!students"
              variant="outline-primary"
              @click="exportFile"
            >
              Експортувати таблицю
            </b-button>
            <b-button
              variant="outline-primary"
              @click="showFilter = !showFilter"
            >
              Фільтри
              <b-icon :icon="!showFilter ? 'caret-down' : 'caret-up'" font-scale="1"/>
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mt-3" v-show="showFilter">
      <Filters @submit="updateStudents" ref="filters"></Filters>
    </b-container>

    <TableStudents ref="tableStudents" :students="students" :meta="meta" @getStudents="updateStudents"/>
  </div>
</template>

<script>
import TableStudents from "@/components/TableStudents";
import api from "@/api";
import {ALLOWED_REQUEST_PARAMETERS, API} from "@/api/constants-api";
import Toasts from "@/mixins/Toasts";
import Filters from "@/components/Filters/Filters";

export default {
  name: "StudentsPage",

  data(){
    return {
      students: [],
      meta: {},
      countNotIdentifiedStudents: 0,
      countNotIdentifiedSupervisors: 0,
      showFilter: false,
    }
  },

  components: {
    Filters,
    TableStudents,
  },
  mixins: [Toasts],
  created() {
    this.getStudents();
    this.getCountNotIdentifiedStudents();
    this.getCountNotIdentifiedSupervisors();
  },

  methods: {
    updateStudents() {
      this.getStudents(this.$refs.tableStudents.current_page, this.$refs.filters.getParams());
    },

    async getStudents(page = 1, queryParams = null) {

      const options = this.GlobalHandlingRequestParameters(ALLOWED_REQUEST_PARAMETERS.GET_ASU_ALL_STUDENTS, queryParams);

      const {data} = await api.get(API.GET_STUDENTS_ALL,
        {page: page},
        {showLoader: true, params :{...options}}
        ).catch(er => {
          console.log(er)
          this.ToastError(er.response.data.message);
        })

      const { data:students, meta  } = data;

      this.students = this.GlobalCheckedIsHasAsuId(students);
      this.meta = meta;
    },
    async getCountNotIdentifiedStudents() {
      const { data } = await api.get(API.COUNT_NOT_IDENTIFIED_STUDENTS).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })

      const { countNotIdentifiedStudents  } = data;
      this.countNotIdentifiedStudents = countNotIdentifiedStudents;
    },
    async getCountNotIdentifiedSupervisors() {
      const { data } = await api.get(API.COUNT_NOT_IDENTIFIED_SUPERVISORS).catch(er => {
        console.log(er)
        this.ToastError(er.response.data.message);
      })

      const { countNotIdentifiedSupervisors  } = data;
      this.countNotIdentifiedSupervisors = countNotIdentifiedSupervisors;
    },
    exportFile(){
      api.get(API.EXPORT_STUDENTS_ALL, {}, {showLoader: true, responseType: 'arraybuffer'}).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'students-excel.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch((error) => {
        this.ToastError(error.response.data.message);
      })
    },
  }
}
</script>

<style scoped>

</style>
