import api from '@/api/index';
import {API} from '@/api/constants-api';

export default {
  namespaced: true,

  state: {
    studentData: null,
  },

  getters: {
    student: state => state.studentData
  },

  mutations: {
    setStudentData(state, student) {
      state.studentData = student;
    }
  },

  actions: {
    getStudentData({ commit }) {
      api
        .get(API.STUDENT)
        .then(response => {
          commit('setStudentData', response);
        })
        .catch( console.log('error fetch student data'));
    }
  }
};