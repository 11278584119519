<template>
  <b-container>
    <Cards :menu="menu"/>
  </b-container>
</template>

<script>
import navigation from "@/utils/menu-constants";
import Cards from "@/components/base/Cards";
import {mapGetters} from "vuex";

export default {
  name: "HomePage",
  components: {
    Cards
  },
  data() {
    return {
      menu: [],
    }
  },
  computed: {
    ...mapGetters({
      user : 'auth/user'
    })
  },
  mounted() {
    this.menu = this.getMenu();
  },
  methods: {
    getMenu() {
      const menu = navigation.getItems(this.user).find((item) => item.route === this.$router.currentRoute.name);
      if (menu !== null || menu !== undefined){
        const {submenu} = menu;
        return submenu;
      }
    }
  }


  }
</script>

<style scoped>
  a:hover {
    text-decoration: none;
  }
  .card {
    overflow:hidden;
  }
  .card .card-body{
    transition: all .3s ease-in-out;
  }
  a:hover .card .card-body {
    transform: scale(1.1);
    transition: all .3s ease-in-out;
  }
</style>
