<template>
  <div id="app">
    <router-view/>
    <loader :show="loading" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import Loader from "@/components/base/Loader";
export default {
  components: {Loader},
  computed: {
    ...mapGetters({
      errors: ['errors']
    }),
    ...mapState('loader', ['loading'])
  },
  watch: {
    errors() {
      console.log('errore')
      this.showAlert();
    }
  },
  methods: {
    showAlert() {
      if (Object.keys(this.errors).length !== 0) {
        let errors = '';

        for (const messages of Object.values(this.errors)) {
          if (typeof messages !== "string") {
            for (const error of messages) {
              errors += `${error} \n`
            }
          } else {
            errors += `${messages} \n`;
          }
        }

        this.$bvToast.toast(errors, {
          title: `Помилка`,
          toaster: 'b-toaster-top-right',
          solid: true,
          variant: 'danger',
          appendToast: true
        })
      }
    }
  }
};
</script>

<style lang="scss">
  @import "assets/scss/base";
</style>
