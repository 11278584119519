<template>
  <h1>{{title}}</h1>
</template>

<script>
export default {
  name: "Header",
  props: [
    'title'
  ],
}
</script>

<style scoped>
h1 {
  font-size: 1.6rem;
  margin-bottom: 1.6rem;
}

</style>