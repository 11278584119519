<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <b-navbar-brand href="#">
      <Logo width="100px"/>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <h5 v-if="user && user.mode"><b-badge variant="warning">Включений режим блокування Модераторів</b-badge></h5>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <template v-for="(item, key) in menu">
          <b-nav-item :to="{name: item.route}"
            :key="key"
            :exact="item.route === 'Home'"
            exact-active-class="active"
          >
            {{ item.title }}
          </b-nav-item>
        </template>
        <b-nav-item @click="logout">
          Вийти
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Logo from "@/components/base/Logo";
import navigation from "@/utils/menu-constants";
import { mapActions, mapGetters } from "vuex";
export default {

  name: "Navbar",
  components: {
    Logo
  },
  data() {
    return {
      menu: null,
    }
  },
  computed: {
    ...mapGetters({
      user : 'auth/user'
    })
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    ...mapActions("auth", ["sendLogoutRequest"]),
    logout() {
      this.sendLogoutRequest().then( () => this.$router.push({ name: "Login" }) );
    },
    getMenu() {
      this.menu = navigation.getItems(this.user)
    }
  }
}
</script>

<style scoped>
.navbar-nav .nav-link.router-link-active {
  color: #ffffff;
}
</style>
