<template>
  <b-container>
    <b-row class="justify-content-md-between align-items-center pb-3">
      <b-col cols="12" lg="7" class="mb-3 mb-lg-0">
        <span>Дата останнього оновлення <b-badge variant="success">{{ updated }}</b-badge></span>
      </b-col>
      <b-col cols="12" lg="5" class="d-flex justify-content-center justify-content-lg-end">
        <b-button-group>
          <b-button
              variant="outline-primary"
              class="d-block ml-auto d-print-none"
              @click="getForcedDepartmentRating">
            Оновити
          </b-button>
          <b-button class="d-block ml-auto d-print-none" variant="primary" @click="print">Завантажити</b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <TableRating :rating="rating">
      <Header :title="department"/>
    </TableRating>

    <Loader :show="loader"/>
  </b-container>

</template>

<script>
import api from '@/api'
import {API} from '@/api/constants-api';
import Loader from '@/components/base/Loader';
import TableRating from '@/components/TableRating';
import Toasts from '@/mixins/Toasts';
import Header from "@/components/base/Header";

export default {
  name: "RatingDepartmentPage",
  data() {
    return {
        department: null,
        updated: null,
        status: true,
        loader: false,
        rating: [],
    }
  },
  components: {
    Loader,
    TableRating,
    Header,
  },
  created() {
  },
  mounted() {
    this.getRating();
    if (this.$route.params.name != null) {
      this.department = this.$route.params.name
    }
  },
  methods: {
    async getRating() {
      this.loader = true;
      const { data } = await api.get(`${API.RATING}/${this.$route.params.id}`).catch(er => {
        this.loader = false;
        this.ToastError(er.response.data.message);
        console.log(er);
      })

      const { rating, updated, department } = data;
      this.rating = rating;
      this.updated = updated;
      this.department = department;
      this.loader = false;
    },
    async getForcedDepartmentRating() {
      this.loader = true;
      const { data } = await api.get(`${API.FORCED_DEPARTMENT_RATING}/${this.$route.params.id}`).catch(er => {
        console.log(er);
        this.ToastError(er.response.data.message);
        this.loader = false;
      })

      const { rating, updated, department } = data;
      this.rating = rating;
      this.updated = updated;
      this.department = department;
      this.loader = false;
    },
    print() {
      window.print();
    }
  }

}
</script>

<style scoped>

</style>
