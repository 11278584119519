<template>
  <b-input-group>
    <b-form-input
      :type="type" trim
      class="form-control"
      :placeholder="placeholder"
      :value="password"
      :readonly="readonly"
      :state="state"
      v-on:focus="$event.target.select()"
      ref="password"
    >
    </b-form-input>
    <b-input-group-append>
      <b-button variant="outline-primary" @click="copy()">
        <b-icon icon="files" font-scale="1"/>
      </b-button>
      <b-button variant="outline-primary" @click="generate()">
        <b-icon icon="arrow-repeat" font-scale="1"/>
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  name: "generate-password",
  props : {
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: '32'
    },
    characters: {
      type: String,
      default: 'a-z,A-Z,0-9,#'
    },
    placeholder: {
      type: String,
      default: 'Password'
    },
    auto: [String, Boolean],
    value: '',
    readonly: false,
    state: '',
  },
  data: function() {
    return {
      password: this.value
    }
  },
  mounted: function() {
    if(this.auto == 'true' || this.auto == 1) {
      this.generate();
    }
  },
  watch: {
    password() {
      this.$emit('input', this.password);
    }
  },
  methods: {
    copy() {
      this.$refs.password.focus();
      document.execCommand('copy');
      this.$bvToast.toast(`Скопійовано`, {
        title: `Скопійовано`,
        variant: 'success',
        autoHideDelay: 500,
        solid: true,
        bodyClass: ['d-none']
      })
    },
    generate () {
      let charactersArray = this.characters.split(',');
      let CharacterSet = '';
      let password = '';

      if( charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz';
      }
      if( charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
      if( charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789';
      }
      if( charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|';
      }

      for(let i=0; i < this.size; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
      }
      this.password = password;
    }
  }
}

</script>

<style scoped lang="scss">
.btn{
  outline: none !important;
}
.btn.btn-primary {
  background-color: $purple;
  border-color: $purple;
  outline: none;
&:hover {
   background-color: darken($purple, 10%);
   border-color: darken($purple, 10%);
 }
&:active, &:focus {
   background-color: lighten($purple, 5%);
   border-color: lighten($purple, 5%);
 }
& .fa {
    padding-right: 4px;
  }
}
.label-primary {
  background-color: $purple;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
}
.form-group {
  margin-bottom: 25px;
}
small {
  font-weight: normal;
  color: #b4bcc3;
}
</style>
