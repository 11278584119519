<template>
  <b-modal
    v-model="dialog"
    id="modal-destroy"
    centered
    :title="title"
    header-bg-variant="danger"
    header-text-variant="light"
    cancel-title="Ні"
    ok-title="Так"
    cancel-variant="outline-danger"
    ok-variant="outline-primary"
    @ok.prevent="$emit('destroy', item)"
    @hidden="$emit('reset')"
  >
    <p class="my-2">{{text}}</p>
  </b-modal>
</template>

<script>
export default {
  name: "mDestroy",
  data() {
    return {
      dialog: this.open
    }
  },
  props: {
    title: {
      default: 'Видалення'
    },
    text: {
      default: ''
    },
    open: {
      default: false,
    },
    item: {
      default: null,
    }
  }

}
</script>

<style scoped>

</style>
