<template>
  <div>
    <b-container fluid class="mt-5">
      <Header :title="title"/>
      <TableSupervisors :supervisors="supervisors" :meta="meta" @getSupervisors="getSupervisors" />
      <Loader :show="loader"/>
    </b-container>
  </div>
</template>

<script>
import api from "@/api";
import {API} from "@/api/constants-api";
import Header from "@/components/base/Header";
import Loader from "@/components/base/Loader";
import TableSupervisors from "@/components/TableSupervisors";
import Toasts from "@/mixins/Toasts";

export default {
  name: "SupervisorsPage",
  data() {
    return {
      title: "Не знайдені Керівники",
      supervisors: [],
      loader: false,
      meta: {}
    }
  },
  components: {
    Header,
    Loader,
    TableSupervisors
  },
  mixins: [Toasts],
  created(){
    this.getSupervisors()
  },
  methods: {
    async getSupervisors(page = 1) {
      this.loader = true;
      const {data} = await api.get(API.SUPERVISORS, {page}).catch(er => {
        this.loader = false;
        if (er.response === undefined) {
          this.ToastError("Проблема Cors");
        } else {
          console.log(er.response.statusText);
          this.ToastError(er.response.statusText);
        }


      })
      const { data:supervisors, meta } = data;
      this.supervisors = supervisors;
      this.meta = meta;
      this.loader = false;
    }
  }
}
</script>

<style scoped>

</style>