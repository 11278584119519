<template>
  <b-container>
    <Cards :menu="menu"/>
  </b-container>
</template>

<script>
import Cards from "@/components/base/Cards";
import navigation from "@/utils/menu-constants";
import {mapGetters} from "vuex";
export default {
  name: "SettingPage",
  components: {
    Cards
  },
  data() {
    return {
      menu: [],
    }
  },
  computed: {
    ...mapGetters({
      user : 'auth/user'
    })
  },
  mounted() {
    this.menu = this.getMenu();
  },
  methods: {
    getMenu() {
      const menu = navigation.getItems(this.user).find((item) => item.route === this.$router.currentRoute.name);
      if (menu !== null || menu !== undefined){
        const {submenu} = menu;
        return submenu;
      }
    }
  }
}
</script>

<style scoped>

</style>
