<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">

    <form ref="form" @submit.stop.prevent="handleSubmit(submit)">
      <b-modal v-model="dialog"
              id="modal-create-marks"
              centered
              :title="title + ' оцінку'"
              header-bg-variant="primary"
              header-text-variant="light"
              cancel-title="Закрити"
              ok-title="Зберегти"
              size="xl"
              @ok.prevent="handleSubmit(submit)"
              @hidden="$emit('reset')"
      >



        <validation-provider
          name="Показник активності"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            id="mark-group"
            label="Показник активності"
            label-for="mark-input"
          >
            <CSelect
              :options="fields.list_marks"
              id="mark-input"
              label="label"
              v-model="item.label"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated"
              :reduce="(option) => option"
              :disabled="!!item.id"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          name="Студент"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            id="student-group"
            label="Студент"
            label-for="student-input"
          >
            <CSelect
              :options="fields.students"
              id="student-input"
              label="full_name"
              v-model="item.full_name_student"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated"
              :reduce="(option) => option.id"
              :disabled="!!item.id"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Науковий керівник"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group
            id="supervisor-group"
            label="Науковий керівник"
            label-for="supervisor-input"
          >
            <CSelect
              :options="fields.supervisors"
              id="supervisor-input"
              label="full_name"
              v-model="item.full_name_supervisor"
              :invalid="validationContext.invalid && validationContext.validated"
              :valid="validationContext.valid && validationContext.validated"
              :reduce="(option) => option.id"
              :disabled="!!item.id"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col col>
            <validation-provider
              name="Опис"
              v-slot="validationContext"
              rules="max:10000"
            >
              <b-form-group
                id="desc-group"
                label="Опис оцінки"
                label-for="desc-input"
              >
                <VueEditor v-model="item.description" :editorToolbar="customToolbar"/>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col col md="12" lg="5" class="pl-lg-0" v-if="placeholder">
            <b-form-group label="Підказка">
              <div class="bg-light-warning py-3 px-3 border border-warning rounded" v-html="placeholder"></div>
            </b-form-group>
          </b-col>
        </b-row>
<!--        <b-form-textarea-->
<!--          id="textarea"-->
<!--          v-model="item.description"-->
<!--          placeholder=""-->
<!--          rows="3"-->
<!--          max-rows="6"-->
<!--        ></b-form-textarea>-->
      </b-modal>
    </form>

  </validation-observer>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import { VueEditor } from "vue2-editor";
import api from "@/api";
import {API, ALLOWED_REQUEST_PARAMETERS} from "@/api/constants-api";

export default {
  name: "mCreateMark",
  components: {CSelect, VueEditor},
  data() {
    return {
      dialog: this.open,
      item: {},
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      placeholder: null,
    }
  },
  props: {
    open: {
      default: false
    },
    title: '',
    mark: null,
    fields: {
      required: true,
    },
  },
  watch: {
    open(v) {
      this.dialog = v
    },
    mark(v) {
      if (v && v !== null) {
        this.item = v;
      }
    },
    'item.label'(v) {
      if (v) {
        this.placeholder = v.placeholder;
      }
    }
  },
  methods: {
    submit() {
      let data = {
        'student_id': this.item.full_name_student,
        'supervisor_id': this.item.full_name_supervisor,
        'list_marks_id': this.item.label.id,
        'description': this.item.description,
      }

      if (this.item.id) {
        data = {...data, id: this.item.id};
      }

      this.$emit('submit', data);
    }
  }
}
</script>

<style scoped lang="scss">
  .bg-light-warning {
    background: rgba(255,193,7,.1);
  }
</style>
