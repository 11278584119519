<template>
  <b-container>
    <Alert :options-props="success"/>
    <action-btn class="fixed bottom-right" @click="openDialog"></action-btn>

    <b-overlay v-if="showTable" spinner-variant="primary">
      <b-table hover :items="users" :fields="fields" head-variant="primary" show-empty>
        <template #cell(actions)="row">
          <b-button
            v-b-tooltip title="Редагувати"
            class="mt-1 mr-1" variant="outline-primary" size="sm" @click="edit(row.item)">
            <b-icon icon="pencil" font-scale="1"/>
          </b-button>
          <b-button
            v-b-tooltip title="Змінити пароль"
            class="mt-1 mr-1" variant="outline-success" size="sm" @click="resetPassword(row.item)">
            <b-icon icon="arrow-repeat" font-scale="1"/>
          </b-button>
          <b-button
            v-b-tooltip title="Видалити"
            class="mt-1 mr-1" variant="outline-danger" size="sm" @click="deleted(row.item)">
            <b-icon icon="trash" font-scale="1"/>
          </b-button>
        </template>
        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>
      </b-table>
    </b-overlay>

    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" @submit.stop.prevent="handleSubmit(onSubmit)">
        <b-modal v-model="dialog"
                 id="modal-prevent-closing"
                 centered
                 :title="(item.id ? 'Редагування' : 'Створення') + ' користувача'"
                 header-bg-variant="primary"
                 header-text-variant="light"
                 cancel-title="Закрити"
                 ok-title="Зберегти"
                 size="lg"
                 @ok.prevent="handleSubmit(onSubmit)"
                 @hidden="reset"
        >
          <Alert :options-props="errors"/>

          <validation-provider
            name="Ім'я"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group
              id="name-group"
              label="Ім'я"
              label-for="name-input"
            >
              <b-form-input
                name="name"
                id="name-input"
                v-model="item.name"
                type="text"
                placeholder="Введіть ім'я"
                :state="GlobalGetValidationState(validationContext)"

              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Email"
            rules="required|email"
            v-slot="validationContext"
          >
            <b-form-group
              id="email-group"
              label="Email"
              label-for="email-input"
            >
              <b-form-input
                name="email"
                id="email-input"
                v-model="item.email"
                type="text"
                placeholder="Введіть Email"
                :state="GlobalGetValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Факультет/Інститут"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group
              id="unit-group"
              label="Факультет/Інститут"
              label-for="unit-input"
            >
              <CSelect
                id="unit-input"
                :options="units"
                label="department"
                v-model="item.unit_id"
                :invalid="validationContext.invalid && validationContext.validated"
                :valid="validationContext.valid && validationContext.validated"
                :reduce="(option) => option.id"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="Роль"
            rules="required"
            v-slot="validationContext"
          >
            <b-form-group
              id="role-group"
              label="Роль"
              label-for="role-input"
            >

              <CSelect
                :options="roles"
                id="role-input"
                label="title"
                v-model="item.role_id"
                :invalid="validationContext.invalid && validationContext.validated"
                :valid="validationContext.valid && validationContext.validated"
                :reduce="(option) => option.id"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="!item.id"
            name="Пароль"
            v-slot="validationContext"
          >
            <b-form-group
              id="password-group"
              label="Пароль"
              label-for="password-input"
            >
              <generate-password
                id="password-input"
                readonly auto="true"
                size="10"
                characters="a-z,A-Z,0-9"
                placeholder="Пароль"
                @input="(v) => {password = v}"
                :state="GlobalGetValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-modal>
      </form>
    </validation-observer>

    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" @submit.stop.prevent="handleSubmit(onResetPassword)">
        <b-modal v-model="dialogPassword"
                 id="modal-password-closing"
                 centered
                 title="Змінити пароль"
                 header-bg-variant="primary"
                 header-text-variant="light"
                 cancel-title="Закрити"
                 ok-title="Зберегти"
                 @ok.prevent="handleSubmit(onResetPassword)"
                 @hidden="reset"
        >
          <Alert :options-props="errors"/>
          <p>
            Ви хочете змінити пароль користувачеві <br>
            <b>{{item.name}} - {{item.email}}</b> ?
          </p>

          <validation-provider
            v-if="item.id"
            name="Пароль"
            v-slot="validationContext"
          >
            <b-form-group
              id="password-group"
              label="Пароль"
              label-for="password-input"
            >
              <generate-password
                id="password-input"
                readonly auto="true"
                size="10"
                characters="a-z,A-Z,0-9"
                placeholder="Пароль"
                @input="(v) => {password = v}"
                :state="GlobalGetValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-modal>
      </form>
    </validation-observer>
  </b-container>
</template>

<script>
import api from '@/api'
import {API} from "@/api/constants-api";

import Alert from '@/components/base/Alert'
import ActionBtn from "@/components/base/ActionBtn";
import CSelect from "@/components/Forms/CSelect";
import GeneratePassword from "@/components/base/generate-password";

export default {
  name: "UsersPage",
  components: {
    GeneratePassword,
    ActionBtn,
    Alert,
    CSelect
  },
  data() {
    return {
      users: [],
      showTable: false,
      dialogPassword: false,
      fields: [
        {key: 'id', label: 'Id'},
        {key: 'role', label: 'Роль'},
        {key: 'name', label: 'Ім\'я'},
        {key: 'email', label: 'Email'},
        {key: 'unit', label: 'Факультет/Інститут'},
        {key: 'actions', label: 'Дії'},
      ],
      dialog: false,
      item: {
        role_id: null,
        name: null,
        email: null,
        unit_id: null,
      },
      units: [],
      roles: [],
      password: '',
      success: {},
      errors: {}
    }
  },
  mounted() {
    this.apiGetUsers();
    this.apiGetUnits();
    this.apiGetRoles();
  },
  methods: {
    async apiGetUsers() {
     const {data} = await api.get(API.USERS, null,{showLoader: true})
      if (data) {
        this.users = data.data;
        this.showTable = true
      }
    },
    openDialog() {
      this.dialog = true;
    },
    onSubmit() {
      const {id, unit_id, role_id} = this.item;
      const data = {
        ...this.item,
        unit: this.units.find(u => u.id === this.item.unit_id).department,
      }

      if (id) {
        api.patch(API.USERS,id, data).then( res => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })

          this.apiGetUsers();

          this.success = {
            message: res.data.message,
            count: 3,
            class: 'bottom-right',
          }

          }).catch( (errors) => {
            this.errors = {
              message: errors.response.data.message,
              count: 3,
              variant: 'danger'
            }
        })
      } else {
        api.post(API.USERS, {...data, password: this.password}).then( res => {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })

          this.apiGetUsers();

          this.success = {
            message: res.data.message,
            count: 3,
            class: 'bottom-right',
          }
        }).catch( (errors) => {
          this.errors = {
            message: errors.response.data.message,
            count: 3,
            variant: 'danger'
          }
        })
      }
    },

    onResetPassword() {
      api.patch(API.USERS + '/reset-password', this.item.id, {id:this.item.id, password: this.password})
        .then( res => {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-password-closing')
        })
        this.success = {
          message: res.data.message,
          count: 3,
          class: 'bottom-right',
        }

      }).catch( (errors) => {
        this.errors = {
          message: errors.response.data.message,
          count: 3,
          variant: 'danger'
        }
      })
    },

    async apiGetRoles() {
      const {data} = await api.get(API.ROLES)
      if (data) {
        this.roles = data.data;
      }
    },
    async apiGetUnits() {
      const {data} = await api.get(API.GET_UNITS_LIST)
      if (data) {
        this.units = data;
      }
    },
    resetPassword(item) {
      this.item = item;
      this.dialogPassword = true;
    },

    deleted(item) {
      api.destroy(API.USERS, item.id).then( res => {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })

        this.apiGetUsers();

        this.success = {
          message: res.data.message,
          count: 3,
          class: 'bottom-right',
        }
      }).catch( (errors) => {
        this.errors = {
          message: errors.response.data.message,
          count: 3,
          variant: 'danger'
        }
      })
    },
    edit(item) {
      this.item = item;
      this.dialog = true;
    },
    reset() {
      this.item = {
        role_id: null,
        name: null,
        email: null,
        unit_id: null,
      }
    }
  }
}
</script>

<style lang="scss">
</style>
