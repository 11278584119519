<template>
  <div class="text-white d-flex justify-content-between align-content-center">
    <span>
        <slot></slot>
    </span>
    <span class="when-opened">
      <b-icon class="text-white" icon="caret-up-fill"/>
    </span>
    <span class="when-closed">
      <b-icon class="text-white" icon="caret-down-fill"/>
    </span>
  </div>
</template>

<script>
export default {
  name: "HeaderCollapseWithArrow",
}
</script>

<style scoped>
.collapsed .when-opened {
  display: none;
}
.not-collapsed .when-closed {
  display: none;
}
</style>
