<template>
  <b-container>
    <b-card-group deck>
      <b-card
        border-variant="primary"
        header-border-variant="primary"
        footer-border-variant="primary"
        footer-bg-variant="transparent"
        header-bg-variant="transparent"
        align="center"
      >
        <template #header>
          Публикації
        </template>
        <b-card-text>Оновить дані за пунктами: <b>2.3, 2.6, 3.2, 3.4, 4.1, 4.3</b></b-card-text>
        <template #footer>
          <b-button @click="updatePublications" type="button" variant="primary">Оновити</b-button>
        </template>
      </b-card>

      <b-card
        border-variant="primary"
        header-border-variant="primary"
        footer-border-variant="primary"
        footer-bg-variant="transparent"
        header-bg-variant="transparent"
        align="center"
      >
        <template #header>
          НДЧ
        </template>
        <b-card-text>Оновить дані за пунктами: <b>1.2, 2.1, 3.5, 4.5</b></b-card-text>
        <template #footer>
          <b-button @click="updateScientificDepartmentPractice" type="button" variant="primary">Оновити</b-button>
        </template>
      </b-card>

      <b-card
        border-variant="primary"
        header-border-variant="primary"
        footer-border-variant="primary"
        footer-bg-variant="transparent"
        header-bg-variant="transparent"
        align="center"
      >
        <template #header>
          Автоматизованої системи Університету
        </template>
        <b-card-text>Оновить дані за пунктами: <b>1.4, 2.7</b></b-card-text>
        <template #footer>
          <b-button @click="updateAsu" type="button" variant="primary">Оновити</b-button>
        </template>
      </b-card>

      <b-card
        border-variant="primary"
        header-border-variant="primary"
        footer-border-variant="primary"
        footer-bg-variant="transparent"
        header-bg-variant="transparent"
        align="center"
      >
        <template #header>
          Доповнення даних про керівників
        </template>
        <b-card-text> - Додасть факультет</b-card-text>
        <template #footer>
          <b-button @click="updateSupervisors" type="button" variant="primary">Оновити</b-button>
        </template>
      </b-card>




    </b-card-group>
    <Loader :show="loader"/>
  </b-container>
</template>

<script>
import api from '@/api'
import {API} from "@/api/constants-api";
import Loader from "@/components/base/Loader";
import Toasts from "@/mixins/Toasts";

export default {
  name: "ExternalDataSynchronization",
  components: {Loader},
  data() {
    return {
      loader: false,
    }
  },
  mixins: [Toasts],
  methods: {
    async updatePublications() {
      this.loader = true;
      const {data} = await api.get(API.IMPORT_PUBLICATIONS).catch((error) => {
        console.log(error);
        this.ToastError(error.response.data.message);
        this.loader = false;
      })
      if (data.hasOwnProperty('messages') && data.messages.length) {
        for (let i = 0; i < data.messages.length; i++) {
          this.ToastSuccess(data.messages[i])
        }
      }
      this.loader = false;
    },
    async updateScientificDepartmentPractice() {
      this.loader = true;
      const {data} = await api.get(API.IMPORT_SCIENTIFIC_DEPARTMENT_PRACTICE).catch((error) => {
        console.log(error);
        this.ToastError(error.response.data.message);
        this.loader = false;
      })
      if (data.hasOwnProperty('messages') && data.messages.length) {
        for (let i = 0; i < data.messages.length; i++) {
          this.ToastSuccess(data.messages[i])
        }
      }
      this.loader = false;
    },
    async updateAsu() {
      this.loader = true;
      const {data} = await api.get(API.IMPORT_STUDENTS_WITH_ASU).catch((error) => {
        console.log(error);
        this.ToastError(error.response.data.message);
        this.loader = false;
      })
      if (data.hasOwnProperty('messages') && data.messages.length) {
        for (let i = 0; i < data.messages.length; i++) {
          this.ToastSuccess(data.messages[i])
        }
      }
      this.loader = false;
    },
    async updateSupervisors() {
      this.loader = true;
      const {data} = await api.get(API.UPDATE_UNIT_SUPERVISORS).catch((error) => {
        console.log(error);
        this.ToastError(error.response.data.message);
        this.loader = false;
      })
      if (data.hasOwnProperty('message')) {
        this.ToastSuccess(data.message)
      }
      this.loader = false;
    }
  }
}
</script>

<style scoped>

</style>
