<template>
  <b-list-group :flush="flush">
    <b-list-group-item v-for="item in marks" class="py-1" :key="item.id">
      <b-row class="mb-2">
        {{item.number}}
        <b-col sm="11">{{ item.label }}</b-col>
        <b-col class="text-center">{{ item.mark }}</b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: "ListMarks",
  props: ['marks', 'flush'],

}
</script>

<style scoped>

</style>
