<template>
  <v-select
    :class="{'is-valid': valid, 'is-invalid': invalid}"
    :disabled="disabled"
    :multiple="multiple"
    v-model="selected"
    :options="options"
    :label="label"
    :reduce="reduce"
    :clearable="clearable"
    :value="value"
    :loading="loading"
    @input="(val) => this.$emit('input', val)"
    @search="(val) => this.$emit('search', val)"
  >
    <template #no-options="{ search, searching, loading }">
      Не знайдено
    </template>
    <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <b-icon variant="primary" icon="caret-down-fill"/>
        </span>
    </template>
  </v-select>
</template>

<script>
export default {
name: "CSelect",
  props: ['options', 'value', 'label', 'multiple', 'disabled', 'reduce', 'clearable', 'valid', 'invalid', 'loading'],
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style lang="scss">
.is-valid .vs__dropdown-toggle {
  border-color: $success;
}
.is-invalid .vs__dropdown-toggle {
  border-color: $danger;
}
</style>
