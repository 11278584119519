<template>
  <b-table hover :items="marks" :fields="fields" head-variant="primary" show-empty stacked="lg" >
    <template #cell(status)="row"  >
      <b-form-checkbox
        v-model="row.item.status"
        name="checkbox-1"
        size="lg"
      />
    </template>
  </b-table>
</template>

<script>
export default {
name: "ListMarks",
  data() {
    return {
      fields: [
        {key: 'number', label: 'Пункт'},
        {key: 'label', label: 'Назва'},
        {key: 'mark', label: 'Оцінка'},
        {key: 'status', label: 'Позначка'},
      ],
    }
  },
  props: {
    marks: {
      type: Array,
      default() {
        return []
      }
    },
  }
}
</script>

<style scoped>

</style>
