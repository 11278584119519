<template>
  <b-container>
    <Alert :options-props="success"/>

    <b-card-group columns v-if="items">
      <b-card
        v-if="!!this.getItemCredit('mode', 'title')"
        no-body
        border-variant="primary"
        :header="this.getItemCredit('mode', 'title')"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-card-text>
          <b-list-group flush>
            <b-list-group-item class="text-center">

              <b-form-checkbox size="lg" switch v-model="mode">{{mode ? 'Включений' : 'Вимкнутий' }}</b-form-checkbox>

            </b-list-group-item>
            <b-list-group-item v-if="!!this.getItemCredit('mode', 'description')">
              <small class="text-muted">{{this.getItemCredit('mode', 'description')}}</small>
            </b-list-group-item>
          </b-list-group>
        </b-card-text>
        <template #footer>
          <b-button class="mx-auto d-flex" variant="primary" @click="onSaveMode()">Зберегти</b-button>
        </template>
      </b-card>

    </b-card-group>
  </b-container>
</template>

<script>
import Alert from '@/components/base/Alert'
import api from '@/api'
import {API} from "@/api/constants-api";

export default {
  name: "SettingSite",
  components: {
    Alert
  },
  data() {
    return {
      mode: false,
      items: null,
      success: null,
    }
  },
  mounted() {
    this.apiGetItems();
  },
  methods: {
    async apiGetItems() {
      const {data} = await api.get(API.SETTINGS, null,{showLoader: true})

      if (data) {
        this.items = data.data;
        this.mode = this.GlobalStringToBoolean(this.getItemCredit('mode', 'value'));
      }
    },

    setItems(id, key, value) {
      this.items = this.items.map(item => {
        if (item.id === id) {
          item[key] = value
        }
        return item;
      })
    },
    getItemCredit(type, key) {
      return this.items.find(item => item.type === type)[key];
    },
    onSaveMode() {
      api.post(API.SETTINGS + '/mode', {id: this.getItemCredit('mode', 'id'), value: this.mode}).then( res => {
        this.setItems(this.getItemCredit('mode', 'id'),'value', this.mode);
        this.$store.commit("auth/setUserData", {...this.$store.getters["auth/user"], mode: this.mode} );
        this.success = {
          message: res.data.message,
          count: 3,
          class: 'bottom-right',
        }

      }).catch( (errors) => {
        console.log(errors)
        this.errors = {
          message: errors.response.data.message,
          count: 3,
          variant: 'danger'
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
