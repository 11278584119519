import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { ROLES } from "@/utils/constants";
import Home from "../views/Home.vue";
import Login from "@/views/pages/LoginLayout";
import BaseLayout from "@/views/pages/BaseLayout";
import Layout from "@/components/base/Layout";
import ImportPage from "@/views/pages/ImportPage";
import RatingPage from "@/views/pages/rating/RatingPage";
import RatingDepartmentPage from "@/views/pages/rating/RatingDepartmentPage";
import SettingPage from "@/views/pages/SettingPage";
import HomePage from "@/views/pages/HomePage";
import StudentsPage from "@/views/pages/StudentsPage";
import SupervisorsPage from "@/views/pages/SupervisorsPage";
import ResetPasswordPage from "@/views/pages/ResetPasswordPage";
import MultiplierPage from "@/views/pages/MultiplierPage";
import ExternalDataSynchronization from "@/views/pages/ExternalDataSynchronization";
import EditStudentSupervisorExcel from "@/views/pages/EditStudentSupervisorExcel";
import Users from "@/views/pages/UsersPage";
import NotFoundPage from "@/views/pages/NotFoundPage";
import SettingSitePage from "@/views/pages/SettingSitePage";
import ProgressStudentsPage from "@/views/pages/progress/StudentsPage";
import ProgressSupervisorsPage from "@/views/pages/progress/SupervisorsPage";
import ProgressMarksPage from "@/views/pages/progress/MarksPage";
import ConstantsPage from "@/views/pages/rating/ConstantsPage";
import ActivityLevels from "@/views/pages/activityLevels/ActivityLevels";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { guest: true },
    component: Login,
  },
  {
    path: "/",
    component: BaseLayout,
    children: [
      {
        path: "",
        name: "Home",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        component: HomePage,
      },
      {
        path: "/import",
        name: "Import",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [],
        },
        component: ImportPage,
      },
      {
        path: "/students",
        name: "Students",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            { text: "Студенти" },
          ],
        },
        component: StudentsPage,
      },
      {
        path: "/supervisors",
        name: "Supervisors",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        component: SupervisorsPage,
      },
      {
        path: "/setting/reset-password",
        name: "ResetPassword",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Змінити пароль" },
          ],
        },
        component: ResetPasswordPage,
      },
      {
        path: "/rating",
        meta: { requiresAuth: true },
        component: Layout,
        children: [
          {
            path: "",
            name: "RatingPage",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin],
              breadCrumb: [
                {
                  text: "Головна",
                  to: { name: "Home" },
                },
                { text: "Рейтинг" },
              ],
            },
            component: RatingPage,
          },
          {
            path: ":id",
            params: "name",
            name: "RatingDepartmentPage",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin],
              breadCrumb: [
                {
                  text: "Головна",
                  to: { name: "Home" },
                },
                {
                  text: "Рейтинг Факультетів та Інститутів",
                  to: { name: "RatingPage" },
                },
                { text: "Рейтинг кафедр" },
              ],
            },
            component: RatingDepartmentPage,
            beforeEnter: (to, from, next) => {
              if (/^[0-9]+$/.test(to.params.id)) {
                next();
              } else {
                next("/");
              }
            },
          },
        ],
      },
      {
        path: "/setting/multiplier",
        name: "Multiplier",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Коефіцієнти" },
          ],
        },
        component: MultiplierPage,
      },
      {
        path: "/setting",
        name: "Setting",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            { text: "Налаштування" },
          ],
        },
        component: SettingPage,
      },
      {
        path: "/setting/external-data-synchronization",
        name: "ExternalDataSynchronization",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Синхронізація даних ззовні" },
          ],
        },
        component: ExternalDataSynchronization,
      },
      {
        path: "/setting/constants",
        name: "Constants",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Показники" },
          ],
        },
        component: ConstantsPage,
      },
      {
        path: "/setting/users",
        name: "Users",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Додавання користувачів" },
          ],
        },
        component: Users,
      },
      {
        path: "/setting/setting-site",
        name: "SettingSite",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Налаштування сервісу" },
          ],
        },
        component: SettingSitePage,
      },
      {
        path: "/setting/activity-levels",
        name: "ActivityLevels",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            {
              text: "Налаштування",
              to: { name: "Setting" },
            },
            { text: "Рівень активності" },
          ],
        },
        component: ActivityLevels,
      },
      {
        path: "/student-edit/:id",
        name: "EditStudentSupervisorExcel",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        component: EditStudentSupervisorExcel,
        beforeEnter: (to, from, next) => {
          if (/^[0-9]+$/.test(to.params.id)) {
            next();
          } else {
            next("/");
          }
        },
      },
      {
        path: "/progress",
        meta: {
          requiresAuth: true,
          accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
          breadCrumb: [
            {
              text: "Головна",
              to: { name: "Home" },
            },
            { text: "Успішність" },
          ],
        },
        component: () => import("@/views/SimpleLayout"),
        children: [
          {
            path: "",
            name: "Progress",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
            },
            component: SettingPage,
          },
          {
            path: "students",
            name: "ProgressStudents",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
              breadCrumb: [
                {
                  text: "Головна",
                  to: { name: "Home" },
                },
                {
                  text: "Успішність",
                  to: { name: "Progress" },
                },
                { text: "Студенти" },
              ],
            },
            component: ProgressStudentsPage,
          },
          {
            path: "supervisors",
            name: "ProgressSupervisors",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
              breadCrumb: [
                {
                  text: "Головна",
                  to: { name: "Home" },
                },
                {
                  text: "Успішність",
                  to: { name: "Progress" },
                },
                { text: "Керівники" },
              ],
            },
            component: ProgressSupervisorsPage,
          },
          {
            path: "marks",
            name: "ProgressMarks",
            meta: {
              requiresAuth: true,
              accessIsAllowed: [ROLES.ID.admin, ROLES.ID.moderator],
              breadCrumb: [
                {
                  text: "Головна",
                  to: { name: "Home" },
                },
                {
                  text: "Успішність",
                  to: { name: "Progress" },
                },
                { text: "Оцінки" },
              ],
            },
            component: ProgressMarksPage,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    name: "NotFoundPage",
    component: NotFoundPage,
    meta: { guest: true },
  },
  {
    path: "/503",
    name: "MaintenanceMode",
    component: NotFoundPage, //TODO MaintenanceMode
    meta: { guest: true },
  },
  {
    path: "/403",
    name: "Forbidden",
    component: NotFoundPage, // TODo Forbidden
    meta: { guest: true },
  },
  {
    path: "/401",
    name: "Unauthorized",
    component: NotFoundPage, // Todo Unauthorized
    meta: { guest: true },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const getUserRoleId = async () => {
  let userRoleId = store.getters["auth/user"];

  // if (userRoleId === null) {
  await store.dispatch("auth/getUserData");
  userRoleId = store.getters["auth/user"];
  // }
  return userRoleId.role_id;
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("authToken")) {
      await getUserRoleId();

      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// this method to check user and role_id
router.beforeEach(async (to, from, next) => {
  const guest = to.matched.some((record) => record.meta.guest);

  if (!guest) {
    const accessIsAllowed = to.meta.accessIsAllowed;
    const userRoleId = store.getters["auth/user"].role_id;

    if (accessIsAllowed !== undefined) {
      if (accessIsAllowed.includes(userRoleId)) {
        next();
      } else {
        console.log("sds Forbidden");
        next({ name: "Forbidden" });
      }
    } else {
      if (!guest) {
        next({ name: "Forbidden" });
      }
      next();
    }
  }
  next();
});

export default router;
