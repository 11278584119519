<template>
  <div>
    <Navbar></Navbar>
    <bread-crumbs class="d-print-none"/>
    <div class="mt-3">
      <Layout />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/base/Navbar";
import Layout from "@/components/base/Layout";
import BreadCrumbs from "@/components/base/BreadCrumbs";

  export default {
    name: "BaseLayout",
    components: {
      Layout,
      Navbar,
      BreadCrumbs
    }
  }
</script>

<style scoped lang="scss">

</style>
