<template>
  <b-row>
    <b-col cols="5" md="3"><b>{{title}}:</b></b-col>
    <b-col>{{value}}</b-col>
  </b-row>
</template>

<script>
export default {
  name: "InfoStudent",
  props: {
    title: {
      type: String,
      default() {
        return '';
      },
    },
    value: {
      default() {
        return '';
      },
    },
  }
}
</script>

<style scoped>

</style>
