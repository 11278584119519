import { render, staticRenderFns } from "./TableRating.vue?vue&type=template&id=246dcce0&scoped=true&"
import script from "./TableRating.vue?vue&type=script&lang=js&"
export * from "./TableRating.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "246dcce0",
  null
  
)

export default component.exports