<template>
  <div class="d-flex flex-column align-items-center ">
      <span v-if="typeof numerator === 'number'">
        {{numerator}}
      </span>

      <div  v-else class="d-flex align-items-center flex-nowrap">
        <span v-for="(value, index) in numerator" class="text-nowrap">
          <template v-if="index !== 0">&#43;</template>
          {{value['amount']}} &#215; {{ value['multiplier']}}
        </span>
      </div>
    <span class="divider"></span>
    <span>
      {{denominator}}
    </span>
  </div>
</template>

<script>
export default {
  name: "fraction",
  props: ['numerator', 'denominator']
}
</script>

<style scoped>

</style>
