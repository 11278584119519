import {ROLES} from '@/utils/constants';

export const MENU =
  [
    {
      title: "Головна",
      route: 'Home',
      link: null,
      allowed: [ROLES.ID.admin, ROLES.ID.moderator],
      submenu: [
        {
          title: "Студенти",
          route: 'Students',
          icon: 'people',
          allowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        {
          title: "Імпорт",
          route: 'Import',
          icon: 'file-earmark-arrow-up',
          allowed: [],
        },
        {
          title: "Рейтинг",
          route: 'RatingPage',
          icon: 'graph-up',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Синхронізація даних ззовні",
          route: 'ExternalDataSynchronization',
          icon: 'cloud-arrow-down',
          allowed: [ROLES.ID.admin],
        },
      ]
    },
    {
      title: "Імпорт",
      route: 'Import',
      allowed: [],
    },
    {
      title: "Рейтинг",
      route: 'RatingPage',
      allowed: [ROLES.ID.admin],
    },
    {
      title: "Успішність",
      route: 'Progress',
      allowed: [ROLES.ID.admin, ROLES.ID.moderator],
      submenu: [
        {
          title: "Студенти",
          route: 'ProgressStudents',
          icon: 'people',
          allowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        {
          title: "Керівники",
          route: 'ProgressSupervisors',
          icon: 'person-lines-fill', //github
          allowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        {
          title: "Оцінки",
          route: 'ProgressMarks',
          icon: 'hand-thumbs-up',
          allowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
      ]
    },
    {
      title: "Налаштування",
      route: 'Setting',
      allowed: [ROLES.ID.admin, ROLES.ID.moderator],
      submenu: [
        {
          title: "Коефіцієнт",
          route: 'Multiplier',
          icon: 'pie-chart',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Змінити пароль",
          route: 'ResetPassword',
          icon: 'file-lock',
          allowed: [ROLES.ID.admin, ROLES.ID.moderator],
        },
        {
          title: "Синхронізація даних ззовні",
          route: 'ExternalDataSynchronization',
          icon: 'cloud-arrow-down',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Додавання користувачів",
          route: 'Users',
          icon: 'person-plus',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Налаштування сервісу",
          route: 'SettingSite',
          icon: 'gear',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Показники",
          route: 'Constants',
          icon: 'card-checklist',
          allowed: [ROLES.ID.admin],
        },
        {
          title: "Рівень активності",
          route: 'ActivityLevels',
          icon: 'file-earmark-bar-graph',
          allowed: [ROLES.ID.admin],
        },
      ]
    },
  ]

export default {
  getItems(user) {
    let menu = [];

    menu = MENU.filter((item) => {
      if (item.allowed.includes(user.role_id)) {
        if (item.submenu) {
          item['submenu'] = item.submenu.filter((sub) => sub.allowed.includes(user.role_id))
        }
        return item;
      }
    })

    return menu;
  }
}

