import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth";
import student from '@/store/modules/student';
import loader from "@/store/modules/loader";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errors: {}
  },
  getters: {
    errors: state => state.errors
  },
  mutations: {
    setErrors(state, errors) {
      state.errors = errors;
    }
  },
  actions: {
  },
  modules: {
    auth,
    student,
    loader
  }
})
