export default {
  components: {},
  data() {
    return {
      // text: 'test'
    }
  },
  methods: {
    GlobalHandlingRequestParameters(permitted, params) {
      let obj = {};
      for (let param in params) {
        if (Object.prototype.hasOwnProperty.call(permitted, param)) {
          if (params[param] !== undefined && params[param] !== '' && params[param] !== null && params[param].lengths !== 0) {
            obj[permitted[param]] = params[param];
          }
        }
      }
      return obj;
    },
    GlobalStringToBoolean(stringValue) {
      switch(stringValue?.toLowerCase()?.trim()){
        case "true":
        case "yes":
        case "1":
          return true;

        case "false":
        case "no":
        case "0":
        case null:
        case undefined:
          return false;

        default:
          return JSON.parse(stringValue);
      }
    },
    GlobalGetValidationState({dirty, validated, valid = null}) {
        return dirty || validated ? valid : null;
    },
    GlobalCheckedIsHasAsuId(students) {
      return students.map(function (student) {
        student['messages_is_has_asu_id'] = [];
        if (student.asu_id === null ) {
          // student['_rowVariant'] = 'warning';
          student['messages_is_has_asu_id'].push(`Студент ${student.full_name} не знайдений в системі АСУ` );
          if (student.import_type) {
            student['messages_is_has_asu_id'].push(`Система: ${student.import_type}`);
          }
        }

        if (student.supervisors.length) {
          student.supervisors.map(function (supervisor) {
            if (supervisor.asu_id === null) {
              // student['_rowVariant'] = 'warning';
              student['messages_is_has_asu_id'].push(`Керівник ${supervisor.full_name} не знайдений в системі АСУ`);
              if (supervisor.import_type) {
                student['messages_is_has_asu_id'].push(`Система: ${supervisor.import_type}`);
              }
            }
          })
        }

        return student
      })
    },
  }
}
