<template>
  <div>
    <b-container>
      <b-row class="justify-content-md-between">
        <b-col col lg="7">
          <b-button variant="light">
            Всього записів
            <b-badge variant="warning">{{meta.total}}</b-badge>
          </b-button>
        </b-col>
        <b-col col lg="5" class="text-right">
          <b-button-group>
            <b-button
              variant="outline-primary"
              @click="showFilter = !showFilter"
            >
              Фільтри
              <b-icon :icon="!showFilter ? 'caret-down' : 'caret-up'" font-scale="1"/>
            </b-button>
            <b-button
              variant="outline-primary"
              @click="create"
            >
              Записати оцінку
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="mt-3" v-show="showFilter">
        <FiltersByMarks
          ref="filters"
          :filters="filters"
          @init="(filters) => apiGetItems(this.current_page, filters)"
        />
      </b-row>
    </b-container>

    <b-container fluid class="mt-5">
      <b-table hover :items="items" :fields="visibleFields" ref="tableStudents" head-variant="primary" show-empty stacked="lg" >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'verification' ? '120px' : field.key === 'actions' ? '180px' : field.key === 'actions' }"
          >
        </template>

        <template #cell(id)="row">
          {{ meta.from + row.index }}
        </template>

        <template #cell(full_name_student)="row">
          <p class="mb-0">{{row.item.full_name_student}}</p>
          <p class="mb-0">{{row.item.student.unit}}</p>
          <p class="text-muted">{{row.item.student.department}}</p>
        </template>

        <template #cell(full_name_supervisor)="row">
          <p class="mb-0">{{row.item.full_name_supervisor}}</p>
          <p class="mb-0">{{row.item.supervisor.unit}}</p>
          <p class="text-muted">{{row.item.supervisor.department}}</p>
        </template>

        <template #cell(verification)="row">
          <b-button variant="success"
                    v-if="row.item.verification === null || row.item.verification === 0"
                    class="mt-1 mr-1"
                    size="sm" @click="verification(row.item.id, true)"
          >
            Так
          </b-button>
          <b-button variant="danger"
                    v-if="row.item.verification === null || row.item.verification === 1"
                    class="mt-1 mr-1"
                    size="sm"
                    @click="verification(row.item.id, false)"
          >
            Ні
          </b-button>
        </template>


        <template #cell(actions)="row">
          <b-button
            v-b-tooltip
            v-if="row.item.description"
            class="mt-1 mr-1"
            variant="outline-info"
            size="sm"
            @click="row.toggleDetails"
            title="Докладніше">
            <b-icon icon="eye" font-scale="1"/>
          </b-button>
          <b-button
            v-if="row.item.actions.edit"
            v-b-tooltip class="mt-1 mr-1"
            variant="outline-primary"
            size="sm"
            title="Редагувати"
            @click="edit( row.item )"
          >
            <b-icon icon="pencil" font-scale="1"/>
          </b-button>
          <b-button
            v-if="row.item.actions.delete"
            v-b-tooltip class="mt-1 mr-1"
            variant="outline-danger"
            size="sm"
            title="Видалити"
            v-b-modal.modal-destroy
            @click="() => { item = row.item; dialogDestroy = false }"
          >
            <b-icon icon="trash" font-scale="1"/>
          </b-button>
        </template>

        <template #row-details="row">
          <b-row>
            <b-col col :lg="row.item.comment ? 8: 12">
              <b-card
                no-body border-variant="info" header="Опис" header-bg-variant="info"
              >
                <b-card-body>
                  <b-card-text>
                    <span v-html="row.item.description"></span>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col col lg="4" v-if="row.item.comment">
              <b-card
                no-body border-variant="warning" header="Чому було не веріфіковано."
                header-bg-variant="warning"
              >
                <b-card-body>
                  <b-card-text>
                    {{row.item.comment}}
                  </b-card-text>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </template>

        <template #empty="scope">
          <h6 class="text-center my-2">Немає записів для показу</h6>
        </template>

        <template #table-caption v-if="meta && meta.total !== 0">
          <div class="text-right">
            Показано від {{meta.from}} до {{meta.to}} із {{meta.total}} записів
          </div>
        </template>

      </b-table>
      <b-pagination
        v-if="meta"
        v-model="current_page"
        class="justify-content-center"
        :total-rows="meta.total"
        :per-page="meta.per_page"
      ></b-pagination>
    </b-container>

    <m-create-mark
      :open="showModalCreate"
      :fields="fieldsCreate"
      :title="item ? 'Редагувати' : 'Створити'"
      @submit="store"
      :mark="item"
      ref="mCreateStudent"
      @reset="resetCreate"
    />


    <m-destroy
      v-if="this.item"
      :item="this.item"
      :open="dialogDestroy"
      :text="'Хочите видалити показник студенту - ' + item.full_name_student + '?' "
      @destroy="destroy"
      @reset="dialogDestroy = false"
    />


  </div>
</template>

<script>
import api from "@/api";
import {API, ALLOWED_REQUEST_PARAMETERS} from "@/api/constants-api";
import FiltersByMarks from "@/views/pages/progress/filters/ByMarks";
import mDestroy from "@/components/modal/destroy";
import Toasts from "@/mixins/Toasts";
import MCreateMark from "@/components/modal/Progress/createMark";
import RolesMixin from "@/mixins/RolesMixin";
import {ROLES} from "@/utils/constants";

export default {
  name: "ProgressMarksPage",
  components: {MCreateMark, mDestroy, FiltersByMarks},
  mixins: [Toasts, RolesMixin],
  data() {
    return {
      items: [],
      meta: [],
      current_page: 1,
      filters: [],
      fields: [
        {key: 'id', label: '№', visible: true},
        {key: 'number', label: 'Пункт', visible: true},
        {key: 'label', label: 'Показники активності', visible: true},
        {key: 'full_name_student', label: 'ПІБ студента', visible: true},
        {key: 'full_name_supervisor', label: 'ПІБ керівника', visible: true},
        {key: 'actions', label: 'Дії', visible: true},
        {key: 'verification', label: 'Веріфікація', visible: this.allowedRoles([ROLES.ID.admin])},
      ],
      showFilter: false,
      item: null,
      dialogDestroy: false,
      fieldsCreate: [],
      showModalCreate: false,
    }
  },
  computed: {
    visibleFields() {
      this.fields.map(item => {
        if (item.key === 'verification') {
          item.visible = this.allowedRoles([ROLES.ID.admin]);
        }
      });

      return this.fields.filter(field => field.visible)
    }
  },
  watch: {
    current_page: function (currentPage) {
      this.apiGetItems(currentPage, this.$refs.filters.getFilterData());
    },
  },
  mounted() {
    this.apiGetItems();
    this.apiGetFilters();
  },
  methods: {
    apiGetFilters() {
      api.get(API.PROGRESS_MARKS_FILTERS).then(({data}) => {
        this.filters = data;
      }).catch((errors) => {
        console.log('PROGRESS_MARKS_FILTERS',errors)
      })
    },

    apiGetItems(page = 1, payload) {
      const options = this.GlobalHandlingRequestParameters(ALLOWED_REQUEST_PARAMETERS.GET_PROGRESS_MARKS, {page, ...payload});

      api.get(API.PROGRESS_MARKS, options, {showLoader: true}).then(({data}) => {

        this.items = data.data;
        this.meta = data.meta;

      }).catch((errors) => {
        console.log('PROGRESS_MARKS',errors)
      })
    },

    apiGetCreate() {
      api.get(API.PROGRESS_MARKS_CREATE,null,{showLoader: true}).then(({data}) => {
        this.fieldsCreate = data;
      }).catch((errors) => {
        console.log('PROGRESS_MARKS_CREATE',errors)
      })
    },

    destroy(item) {
      this.$nextTick(() => {
        this.$bvModal.hide('modal-destroy')
      })

      api.destroy(API.PROGRESS_MARKS, item.id).then(({data}) => {
        this.ToastSuccess(data.message);
        this.apiGetItems(this.current_page,this.$refs.filters.getFilterData());
      }).catch((er) => {
        console.log('er PROGRESS_SUPERVISORS', er )
        this.ToastError(er.response.data.message)
      })
    },
    store(item) {
      if (item.id) {
        api.patch(API.PROGRESS_MARKS, item.id, item).then(({data}) => {
          this.ToastSuccess(data.message);
          this.showModalCreate = false;
          this.resetCreate();
          this.apiGetItems(this.current_page,this.$refs.filters.getFilterData());
          this.apiGetFilters();
        }).catch((er) => {
          console.log('er PROGRESS_MARKS', er )
          this.ToastError(er.response.data.message)
        })
      } else {
        api.post(API.PROGRESS_MARKS, item).then(({data}) => {
          this.ToastSuccess(data.message);
          this.showModalCreate = false;
          this.resetCreate();
          this.apiGetItems(this.current_page,this.$refs.filters.getFilterData());
          this.apiGetFilters();
        }).catch((er) => {
          console.log('er PROGRESS_MARKS', er )

          this.ToastError(er.response.data.message)
        })
      }
    },
    verification(id, status) {
      if (status) {
        api.patch(API.PROGRESS_MARKS_APPROVE_VERIFICATION, id, status).then(({data}) => {
          this.ToastSuccess(data.message);
          this.apiGetItems(this.current_page,this.$refs.filters.getFilterData());
        }).catch((er) => {
          console.log('er PROGRESS_MARKS', er )
          this.ToastError(er.response.data.message)
        })
      } else {
        api.patch(API.PROGRESS_MARKS_CANCEL_VERIFICATION, id, status).then(({data}) => {
          this.ToastSuccess(data.message);
          this.apiGetItems(this.current_page,this.$refs.filters.getFilterData());
        }).catch((er) => {
          console.log('er PROGRESS_MARKS', er )
          this.ToastError(er.response.data.message)
        })
      }
    },
    edit(item) {
      this.apiGetCreate();
      this.item = item;
      this.showModalCreate = true;
    },
    async create() {
      this.apiGetCreate();
      this.showModalCreate = true;
    },
    resetCreate() {
      this.$refs.mCreateStudent.item = {};
      this.$refs.mCreateStudent.placeholder = null;
      this.showModalCreate = false;
      this.item = null;
    }
  }
}
</script>

<style scoped>

</style>
