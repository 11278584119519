<template>
  <b-container>
    <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="filters">
      <b-row>
        <b-col cols="12" lg="12">
          <b-form-group label="Факультет/Інститут/Кафедра">
            <CSelect clearable :options="filters.units" label="department" v-model="unit"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        type="submit"
      >
        <b-icon icon="search"/>
        Пошук
      </b-button>
      <b-button class="ml-2" type="reset" variant="outline-secondary">Очистити</b-button>
    </b-form>
  </b-container>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import api from "@/api";
import {API} from "@/api/constants-api";
import Toasts from "@/mixins/Toasts";

export default {
  name: "FilterByConstants",
  components: {CSelect},
  mixins: [Toasts],
  data() {
    return {
      unit: null,
    }
  },
  props: ['filters'],
  methods: {
    onSubmit() {
      this.$emit('init', this.getFilterData());
    },
    getFilterData() {
      return {
        unit: this.unit?.id,
      }
    },
    onReset() {
      const data = {
        unit: this.unit = null,
      };

      this.$emit('init', data);
    }
  }
}
</script>

<style scoped>

</style>
