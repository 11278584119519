<template>
  <b-container>
    <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="filters">
      <b-row>
        <b-col cols="12" lg="12">
          <b-form-group label="Показники активності">
            <CSelect clearable :options="filters.list_marks" label="label" v-model="mark"/>
          </b-form-group>
        </b-col>
        <b-col cols="12" lg="4">
          <b-form-group label="Статус">
            <CSelect clearable :options="filters.verifications" label="text" v-model="verification"/>
          </b-form-group>
        </b-col>
      </b-row>

<!--      <b-row>-->
<!--        <b-col cols="12" lg="6">-->
<!--          <b-form-group-->
<!--            label="Факультет/Інститут"-->
<!--          >-->
<!--            <CSelect :options="filters.units" label="department" v-model="unit"/>-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--        <b-col cols="12" lg="6">-->
<!--          <b-form-group-->
<!--            label="Кафедра"-->
<!--          >-->
<!--            <CSelect :options="departments" label="department" v-model="department"/>-->
<!--          </b-form-group>-->
<!--        </b-col>-->
<!--      </b-row>-->

      <b-button
        variant="outline-primary"
        type="submit"
      >
        <b-icon icon="search"/>
        Пошук
      </b-button>
      <b-button class="ml-2" type="reset" variant="outline-secondary">Очистити</b-button>
    </b-form>
  </b-container>
</template>

<script>
import CSelect from "@/components/Forms/CSelect";
import api from "@/api";
import {API} from "@/api/constants-api";
import Toasts from "@/mixins/Toasts";

export default {
  name: "FiltersByMarks",
  components: {CSelect},
  mixins: [Toasts],
  data() {
    return {
      mark: null,
      verification: null,
      // unit: null,
      // department: null,
      // departments: [],
    }
  },
  props: ['filters'],
  watch: {
    // unit(v) {
    //   if (v !== null) {
    //     this.apiGetDepartments(v.id);
    //   } else {
    //     this.departments = [];
    //   }
    // }
  },
  methods: {
    // apiGetDepartments(id) {
    //   api.get(API.GET_DEPARTMENTS_LIST + '/' + id).then(({data}) => {
    //     console.log('API.GET_DEPARTMENTS_LIST', data);
    //     this.departments = data
    //   }).catch(er => {
    //     console.log(er)
    //     this.ToastError(er.response.data.message);
    //   })
    // },
    onSubmit() {
      const data = {
        mark: this.mark?.id,
        verification: this.verification?.value,
        // unit: this.unit !== null ? this.unit.id : null,
        // department: this.department !== null ? this.department.id : null,
      };
      console.log('data', data);
      this.$emit('init', data);
    },
    getFilterData() {
      return {
        mark: this.mark?.id,
        verification: this.verification?.value,
        // unit: this.unit !== null ? this.unit.id : null,
        // department: this.department !== null ? this.department.id : null,
      }
    },
    onReset() {
      const data = {
        mark: this.mark = null,
        verification: this.verification = null,
        // unit: this.unit = null,
        // department: this.department = null,
      };

      this.$emit('init', data);
    }
  }
}
</script>

<style scoped>

</style>
